import React,{useEffect, useRef, useState} from 'react'
import styles from './Login.module.css'
import { db } from "../../firebaseTools.js";
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";


const schema = yup.object({
    Contact : yup.string().length(10).required(),
    Password : yup.string().required()
  }).required()



function Login() {
  const modalRef = useRef()
    const history = useHistory()
    const [registerLoader,setRegisterLoader] = useState(false)

    const [success,setSuccess] = useState(false)

    const {register,handleSubmit,formState:{errors}} = useForm({
        resolver : yupResolver(schema)
      })


    const onSubmit = async(data) => {
        setRegisterLoader(true)
        const query = db.collection("contacts")
        const snapshot = await query.where('Contact', '==', data.Contact).where('Password','==',data.Password).get();
        if (snapshot.empty) {
            alert('No account found');
            return;
        } else {
            const data = []
            snapshot.forEach(doc => {
                data.push(doc.data())
              });
              sessionStorage.setItem('user',JSON.stringify(data[0]))
            history.push('/tests')
        }
    }

    useEffect(()=>{

      const checkClick = (e) => {
        if(success && modalRef.current && !modalRef.current.contains(e.target)){
          setSuccess(false)
        }
      }

      document.addEventListener('click',checkClick)

      return () => {
        document.removeEventListener('click',checkClick)
      }

    },[success])


  return (
    <>
    {
        registerLoader &&
        <div className={styles.modalContainer}>
           <div style={{
                    width:'fit-content',
                    position:'absolute',
                    top:'50%',
                    transform:'translateY(-50%)',
                    left:'0',
                    right:'0',
                    margin:'auto'
                }}>
                    <ClipLoader color='white' loading={true} size={60} />
            </div>
        </div> 
      }

{
        success &&
        <div className={styles.modalContainer}>
            <div ref={modalRef} style={{
                    width:'fit-content',
                    textAlign:'center',
                    padding:'8vh 10vw',
                    color:'#2f3679',
                    position:'absolute',
                    background:'white',
                    top:'20%',
                    left:'0',
                    right:'0',
                    margin:'auto'
                }}>
                    <div style={{
                        fontFamily:'Poppins',
                        fontSize:'3.5vh',
                        marginTop:'2vh',
                        fontWeight:'bold',
                        }}>
                        <div>
                          <div style={{fontFamily:'Poppins',marginBottom:'2vh'}}>Please Contact</div>

                          <div>
                            <div style={{fontFamily:'Poppins',fontSize:'2.2vh'}}>+91 9769268512</div>
                            <div style={{fontFamily:'Poppins',fontSize:'2.2vh'}}>+91 9928251997</div>
                          </div>
                        </div>
                    </div>
            </div>  
        </div> 
      }


    <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>

        <div style={{textAlign:'center',fontFamily:'Poppins',fontWeight:'bold',fontSize:'3vh',color:'#2f3679'}}>
            Login Here
        </div> 

        <div className={styles.formItem}>
        <label  className={styles.label}> Contact Number <span style={{color:'red'}}>*</span> </label>
        <input type="text" className={styles.formInput} placeholder="Enter phone number" name="Contact" 
        {...register("Contact")}/>
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Contact?.message}</div>
        </div>

        <div className={styles.formItem}>
        <label  className={styles.label}> Password <span style={{color:'red'}}>*</span> </label>
        <input type="text" className={styles.formInput} placeholder="Enter password" name="Password" 
        {...register("Password")}/>
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Password?.message}</div>
        </div>

        <div style={{textAlign:'right'}}>
          <div onClick={()=>setSuccess(true)} style={{fontFamily:'Poppins',color:'red',fontSize:'2vh',cursor:'pointer'}}>
            Forgot password?
          </div>
        </div>

        <div className={styles.formItem}>
            <input className={styles.formButton} type="submit"/>
        </div>

        </form>
    </div>
    </>
  )
}

export default Login