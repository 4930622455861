import React from 'react'
import styles from '../TestResult.module.css'

function Test5({result}) {
  return (
    <>
            {
                result &&
                <>
                {
                    result.Score &&
                    <div className={styles.section}>
                        <div className={styles.Title}>Score</div>
                        {
                            result.Score["Self Conduct"] &&
                            <div className={styles.content}>Self Conduct: {result.Score["Self Conduct"]}</div>
                        }
                        {
                            result.Score["Self Perception"] &&
                            <div className={styles.content}>Self Perception: {result.Score["Self Perception"]}</div>
                        }
                        {
                            result.Score["Social Conduct"] &&
                            <div className={styles.content}>Social Conduct: {result.Score["Social Conduct"]}</div>
                        }
                        {
                            result.Score["Social Consciousness"] &&
                            <div className={styles.content}>Social Consciousness: {result.Score["Social Consciousness"]}</div>
                        }
                    </div>
                }

                {
                    result.Attribute &&
                    <div className={styles.section}>
                        <div className={styles.Title}>Attribute</div>
                        <div className={styles.content}>{result.Attribute}</div>
                    </div>
                }

                {
                    result.Analysis &&
                    <div className={styles.section}>
                        <div className={styles.Title}>Analysis</div>
                        {
                            result.Analysis.Brief &&
                            <div className={styles.section}>
                                <div className={styles.subTitle}>Brief</div>
                                <div>
                                    {
                                        result.Analysis.Brief.map(item => {
                                            return (
                                                <div className={styles.content}>{item}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }

{
                        result.Analysis.Emotions &&
                        <div className={styles.section}>
                            <div className={styles.subTitle}>Emotions</div>
                            <div>
                                {
                                    result.Analysis.Emotions.map(item => {
                                        return (
                                            <div className={styles.content}>{item}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }

{
                        result.Analysis["Remember!!"] &&
                        <div className={styles.section}>
                            <div className={styles.subTitle}>Remember!!</div>
                            <div>
                                {
                                    result.Analysis["Remember!!"].map(item => {
                                        return (
                                            <div className={styles.content}>{item}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    </div>
                }

                </>
            }
            </>
  )
}

export default Test5