import React, { useEffect, useRef } from "react";
import "./Footerbody.css";
import { useState } from "react";
import i1 from "../../../assets/images/f.png";
import i2 from "../../../assets/images/inst.png";
import i3 from "../../../assets/images/in.png";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import Carousel from "react-elastic-carousel";
import Programs, { Popedprogram } from "../Programs/Program";
import { useDispatch, useSelector } from "react-redux";
import footer1 from "../../../assets/images/footerleft.png";
import footer2 from "../../../assets/images/footerright.png";
import Grass from "../../../assets/images/Grass.png"
import Girl from "../../../assets/images/Sitting girl.png"
import Flower3 from "../../../assets/images/Flowers 3.png"
import Flower4 from "../../../assets/images/Flowers 4.png"
import Ellipse12 from "../../../assets/images/Ellipse 12.png"
import Facebook from "../../../assets/images/32.png"
import Instagram from "../../../assets/images/34.png"
import Linkedin from "../../../assets/images/33.png"
import {
  CheckButton,
  selectCheck,
  selectToggle,
  ToggleTheForm,
} from "../../../feature/ToggleSlice";

import { db } from "../../../firebaseTools";
import { useForm } from "react-hook-form";

const Validate = (data) => {
  db.collection("subscribeEmail").doc().set({
    email: data.email,
  });
  notification();
};

const notification = () => {
  toast.success("You have successfully subscribed", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
// const breakPoints = [
//   { width: 1, itemsToShow: 1 },
//   { width: 400, itemsToShow: 2, itemsToScroll: 2 },
//   { width: 600, itemsToShow: 3 },
//   { width: 800, itemsToShow: 4 },

//   { width: 1000, itemsToShow: 5 },
// ];

function Footerbody() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  
  const checkbutton = useSelector(selectCheck);
  const dispatch = useDispatch();
  const [mediaImage, setmediaImage] = useState([]);

  const togglecheck = useSelector(selectToggle);

  useEffect(() => {
    // db.collection("mediadatabase").onSnapshot((snapshot) => {
    //   setmediaImage(
    //     snapshot.docs.map((doc) => ({
    //       id: doc.id,
    //       data: doc.data(),
    //     }))
    //   );
    // });
    if (checkbutton === true) {
      dispatch(CheckButton(false));
    }
  }, []);

  let [toggle3, settoggle1] = useState(0);

  function Popover() {
    settoggle1(!toggle3);
    if (toggle3 == 1) {
      dispatch(ToggleTheForm(true));
      document.getElementById("popover").style.display = "block";
    }
    if (toggle3 == 0) {
      dispatch(ToggleTheForm(false));
      document.getElementById("popover").style.display = "none";
    }
  }
  return (
    // <div style={{backgroundColor:"#F5F8FF"}}>
    //   {/* <br/> */}
    //   {/* <br/> */}
    //   {/* <br/> */}
    //   {/* <div id="homepagemedia">
    //         <Carousel breakPoints={breakPoints} className="media_slider">
    //           {mediaImage.map((item, index) => (
    //             <a href={item.data.link} target=" _blank" key={index}>
    //               <img
    //                 className="media_image"
    //                 src={item.data.image}
    //                 alt="..."
    //               />
    //             </a>
    //           ))}
    //         </Carousel>
    //       </div> */}
    //   {/* <br /> */}
    //   <div id="footerend">
    //      {/*<div className="footertitle">Lorem Ipsum</div>
    //     <div className="footertext">
    //      <br />
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec maximus
    //       auctor dui, et varius felis eleifend et. Cras sodales ipsum pharetra
    //       sodales ultrices. Sed ullamcorper felis a nulla elementum suscipit.
    //       Pellentesque finibus rutrum augue, nec fermentum metus scelerisque sit
    //       amet. 
    //     </div>
    //     <br /> */}
    //     <div className="subscribe"  style={{paddingTop:"25px"}}>
    //       <button
    //         id="subbtn"
    //         style={{width: "100px", height: "40px", border: "none" }}
    //         type="submit"
    //         onClick={handleSubmit(Validate)}
    //       >
    //         Subscribe
    //       </button>
    //       <input
    //         id="newsletter1"
    //         type="text"
    //         name="email"
    //         {...register("email", {
    //           required: "Email is required.",
    //           pattern: {
    //             value: /^[a-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/,
    //             message: "Please enter valid email.",
    //           },
    //         })}
    //         className="form-control"
    //         placeholder="Enter your email address"
    //         style={{ width: "300px", height: "30px",zIndex:"5" }}
    //       />
    //       {errors?.email && (
    //         <p
    //           style={{
    //             alignItems: "center",
    //             justifyContent: "center",
    //             color: "red",
    //             fontSize: "20px",
    //             paddingTop: "10px",
    //           }}
    //         >
    //           {errors.email.message}
    //         </p>
    //       )}
    //     </div>
    //     <br />
    //   </div>
    //   <div id="footericonsbody" style={{ backgroundColor: "#F5F8FF", position: "relative" }}>
    //     <br />
    //     {/* <br /> */}
    //     {/* <br /> */}
    //     <div className="iconscontainer">
    //       <div className="facebook" style={{ marginRight: "5%" }}>
    //         <a target="_blank " href="https://www.facebook.com/infigonfutures">
    //           <img
    //             src={i1}
    //             alt="Facebook"
    //             classname="fimg"
    //             style={{ marginTop: "30%" }}
    //           ></img>
    //         </a>
    //       </div>
    //       <div className="instagram" style={{ marginRight: "5%" }}>
    //         <a
    //           target="_blank "
    //           href="https://www.instagram.com/infigon_futures/"
    //         >
    //           <img
    //             src={i2}
    //             alt="instgram"
    //             classname="iimg"
    //             style={{ marginTop: "30%" }}
    //           ></img>
    //         </a>
    //       </div>
    //       <div className="linkedin">
    //         <a
    //           target="_blank "
    //           href="https://www.linkedin.com/company/infigon-futures"
    //         >
    //           <img
    //             src={i3}
    //             alt="instgram"
    //             classname="limg"
    //             style={{ marginTop: "30%" }}
    //           ></img>
    //         </a>
    //       </div>
    //     </div>

    //     <br />
    //     <br />
    //     <div className="redirecttitles" style={{zIndex:"5",position:"relative"}}>
    //       <Link
    //         to="/home"
    //         style={{ textDecoration: "none", marginRight: "5%" }}
    //       >
    //         <p
    //           className="rtitles"
    //           onClick={() => {
    //             window.scrollTo({ top: 0, behavior: "smooth" });
    //           }}
    //         >
    //           Home
    //         </p>
    //       </Link>
    //       <Link
    //         to="/aboutus"
    //         style={{ textDecoration: "none", marginRight: "5%" }}
    //       >
    //         <p
    //           className="rtitles"
    //           onClick={() => {
    //             window.scrollTo({ top: 0, behavior: "smooth" });
    //           }}
    //         >
    //           About us
    //         </p>
    //       </Link>
    //       <p
    //         className="rtitles"
    //         style={{ marginRight: "5%" }}
    //         onMouseEnter={() => {
    //           window.scrollTo({ top: 0, behavior: "smooth" });
    //           Popedprogram();
    //         }}
    //       >
    //         Programs
    //       </p>
    //       <p
    //         className="rtitles"
    //         onClick={() => {
    //           // window.scrollTo({ top: 0, behavior: "smooth" });

    //           Popover();
    //         }}
    //       >
    //         Get in touch
    //       </p>
    //     </div>
    //     <br />
    //     <br />
    //   </div>
    //   <div id="copyrightbody" style={{ zIndex:"5",position:'relative'}}>
    //     <br />
    //     <div className="copyrighttext">
    //       © 2021 Company, Inc. All rights reserved.
    //     </div>
    //     <br />
    //   </div>
    //   <div id="footerimages">
    //       <div className="fleft">
    //         <img src={footer1} alt=".." style={{width: '80%',height: '100%'}}></img>
    //       </div>
    //       <div className="fright">
    //         <img src={footer2} alt=".." style={{width: '100%',height: '100%'}}></img>
    //       </div>
    //     </div>








        <div className="footer">
          <img src={Girl} className="girl"/>
          <img src={Grass} className="grass"/>
          <img src={Flower3} className="flower3"/>
          <img src={Flower4} className="flower4"/>

          <div className="subscribe-container">
            <span className="subscribe-button" onClick={handleSubmit(Validate)}>Subscribe</span>
            <input type="email" className="input" 
            {...register("email", {
              required: "Email is required.",
              pattern: {
                value: /^[a-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/,
                message: "Please enter valid email.",
              },
            })}/>
            {
              errors?.email && 
                <span className="error-message">
                  {errors.email.message}
                </span>
            }
          </div>

          <div className="footer-content">
            <div className="container1">
              © 2021 Company, Inc. All rights reserved.
            </div>

            <div className="container2">
              
              <div className="link" onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}>Home</div>            

            
              <div className="link" onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                Popedprogram();
            }}>Programs</div>            

              <Link to="/aboutus" style={{textDecoration:'none',color:'white'}}>
              <div className="link" onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}>About Us</div> 
              </Link>           

            
              <div className="link" onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}>Get In Touch</div>
              
            </div>

            <div className="container3">
              <a href="https://www.facebook.com/infigonfutures">
                <div className="parent">
                  <img src={Ellipse12}/>
                  <img src={Facebook} className="icon"/>
                </div>
              </a>

              <a href="https://www.instagram.com/infigon_futures/">
                <div className="parent">
                  <img src={Ellipse12}/>
                  <img src={Instagram} className="icon"/>
                </div>
              </a>

              <a href="https://www.linkedin.com/company/infigon-futures">
                <div className="parent">
                  <img src={Ellipse12}/>
                  <img src={Linkedin} className="icon"/>
                </div>
              </a>
    
            </div>
          </div>
        </div>
  );
}

export default Footerbody;
