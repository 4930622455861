import React from "react";
import "react-slideshow-image/dist/styles.css";
import Carousel from "react-elastic-carousel";
import progimg1 from "../../../assets/images/Prog6.png";
import progimg2 from "../../../assets/images/Prog10.png";
import progimg3 from "../../../assets/images/Proggrad.png";
import "./Ourprogram.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Ourprogram() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 400, itemsToShow: 2 },
    { width: 800, itemsToShow: 3 },
  ];
  const OurprogramBody = [
    {
      OurTitle: "CLASS 6-9",
      Ourimage: progimg1,
      Ourtext:
        "Detailed self-analysis tests with personalized reports. Handholding for education path preparation, subject selection, competitive examinations and much more!",
      link: "/Classsix",
      readme: "Read More...",
    },
    {
      OurTitle: "CLASS 10-12",
      Ourimage: progimg2,
      Ourtext:
        "Tests for shortlisting career options along with selection of degree and support for end-to-end admissions procedure. Also,develop your skills with personalized mentorship and much more ",
      link: "/Classten",
      readme: "Read More...",
    },
    {
      OurTitle: "GRADUATES",
      Ourimage: progimg3,
      Ourtext:
        "Analyze your personality, select a career, develop your resume and skills through internships and competitions under expert mentorship and much more",
      link: "/Classgraduates",
      readme: "Read More...",
    },
  ];
  const Ourcolors = ["#892B3B", "#1D3740", "#603D13"];
  return (
    <div>
      <div id="our_program" style={{marginTop:"50px"}}>
        <br />
        <br />
        <div id="our_programtitle">Our Programs</div>
        <br />
        <div id="our_programbody" style={{marginTop:"25px"}}>
          <Carousel
            breakPoints={breakPoints}
            autoPlaySpeed={5000}
            arrow={false}
          >
            {OurprogramBody.map((each, index) => (
              <Link to={each.link} style={{ textDecoration: "none" }}>
                <div id="our_programcard" style={{ textAlign: "center" }}>
                  <div
                    className="ourprogramhead"
                    key={index}
                    style={{ color: Ourcolors[index] }}
                  >
                    {each.OurTitle}
                  </div>
                  <br />
                  <div className="ourprogramimage">
                    <img
                      src={each.Ourimage}
                      alt="imges"
                      style={{ height: "140px", width: "150px" }}
                    ></img>
                  </div>
                  {/* <br /> */}
                  <div
                    className="ourprogramtext"
                    key={index}
                    style={{ color: Ourcolors[index], textAlign: "center" }}
                  >
                    {each.Ourtext} <br></br>
                    <Link to={each.link} style={{ textDecoration: "none" }}>
                      <span style={{ color: "blue", fontSize: "14px" }}>
                        {each.readme}
                      </span>
                    </Link>
                  </div>
                </div>
              </Link>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Ourprogram;
