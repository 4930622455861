import React from 'react'
import styles from '../TestResult.module.css'

function Test4({result}) {
  return (
    <>
                {
                    result &&
                    <>

                    {
                        result.Analysis &&
                        <div>
                            <div className={styles.Title}>Analysis</div>
                            {
                                result.Analysis.Altruism &&
                                <div>
                                    <div className={styles.subTitle}>Altruism</div>
                                    <ol>
                                        {
                                            result.Analysis.Altruism.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Altruism.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Altruism.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Altruism.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Altruism.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Altruism.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Altruism.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Altruism.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis.Autonomy &&
                                <div>
                                    <div className={styles.subTitle}>Autonomy</div>
                                    <ol>
                                        {
                                            result.Analysis.Autonomy.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Autonomy.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Autonomy.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Autonomy.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Autonomy.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Autonomy.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Autonomy.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Autonomy.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis.Creativity &&
                                <div>
                                    <div className={styles.subTitle}>Creativity</div>
                                    <ol>
                                        {
                                            result.Analysis.Creativity.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Creativity.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Creativity.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Creativity.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Creativity.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Creativity.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Creativity.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Creativity.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis['Financial Reward'] &&
                                <div>
                                    <div className={styles.subTitle}>Financial Reward</div>
                                    <ol>
                                        {
                                            result.Analysis['Financial Reward'].Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis['Financial Reward'].Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Financial Reward'].Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis['Financial Reward'].Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Financial Reward'].Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis['Financial Reward'].Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Financial Reward'].Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis['Financial Reward'].Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis['Influence'] &&
                                <div>
                                    <div className={styles.subTitle}>Influence</div>
                                    <ol>
                                        {
                                            result.Analysis.Influence.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Influence.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Influence.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Influence.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Influence.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Influence.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Influence.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Influence.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis.Performance &&
                                <div>
                                    <div className={styles.subTitle}>Performance</div>
                                    <ol>
                                        {
                                            result.Analysis.Performance.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Performance.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Performance.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Performance.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Performance.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Performance.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Performance.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Performance.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis.Prestige &&
                                <div>
                                    <div className={styles.subTitle}>Prestige</div>
                                    <ol>
                                        {
                                            result.Analysis.Prestige.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Prestige.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Prestige.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Prestige.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Prestige.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Prestige.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Prestige.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Prestige.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis['Security'] &&
                                <div>
                                    <div className={styles.subTitle}>Security</div>
                                    <ol>
                                        {
                                            result.Analysis.Security.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Security.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Security.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Security.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Security.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Security.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Security.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Security.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis['Self-Development'] &&
                                <div>
                                    <div className={styles.subTitle}>Self-Development</div>
                                    <ol>
                                        {
                                            result.Analysis['Self-Development'].Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis['Self-Development'].Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Self-Development'].Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis['Self-Development'].Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Self-Development'].Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis['Self-Development'].Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Self-Development'].Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis['Self-Development'].Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis.Structure &&
                                <div>
                                    <div className={styles.subTitle}>Structure</div>
                                    <ol>
                                        {
                                            result.Analysis.Structure.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Structure.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Structure.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Structure.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Structure.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Structure.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Structure.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Structure.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis.Variety &&
                                <div>
                                    <div className={styles.subTitle}>Variety</div>
                                    <ol>
                                        {
                                            result.Analysis.Variety.Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis.Variety.Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Variety.Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis.Variety.Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Variety.Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis.Variety.Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis.Variety.Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis.Variety.Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis['Work Conditions'] &&
                                <div>
                                    <div className={styles.subTitle}>Work Conditions</div>
                                    <ol>
                                        {
                                            result.Analysis['Work Conditions'].Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis['Work Conditions'].Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Conditions'].Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis['Work Conditions'].Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Conditions'].Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis['Work Conditions'].Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Conditions'].Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis['Work Conditions'].Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis['Work Life Balance'] &&
                                <div>
                                    <div className={styles.subTitle}>Work Life Balance</div>
                                    <ol>
                                        {
                                            result.Analysis['Work Life Balance'].Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis['Work Life Balance'].Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Life Balance'].Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis['Work Life Balance'].Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Life Balance'].Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis['Work Life Balance'].Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Life Balance'].Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis['Work Life Balance'].Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }

                            {
                                result.Analysis['Work Relationships'] &&
                                <div>
                                    <div className={styles.subTitle}>Work Relationships</div>
                                    <ol>
                                        {
                                            result.Analysis['Work Relationships'].Cons &&
                                            <li>
                                                <div className={styles.subTitle}>Cons:</div> 
                                                <div>{result.Analysis['Work Relationships'].Cons}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Relationships'].Description &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Description: </div>
                                                <div className={styles.content}>{result.Analysis['Work Relationships'].Description}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Relationships'].Meaning &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Meaning:</div> 
                                                <div className={styles.content}>{result.Analysis['Work Relationships'].Meaning}</div>
                                            </li>
                                        }
                                        {
                                            result.Analysis['Work Relationships'].Pros &&
                                            <li className={styles.section}>
                                                <div className={styles.subTitle}>Pros:</div> 
                                                <div className={styles.content}>{result.Analysis['Work Relationships'].Pros}</div>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            }
                        </div>
                    }
                    
                    {
                        result.Attribute &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Attribute</div>
                            <div className={styles.content}>{result.Attribute}</div>
                        </div>
                    }


                    <div className={styles.section}>
                        <div className={styles.Title}>Score</div>
                        <div>
                            <div className={styles.content}>Altruism: {result.Score.Altruism}</div>
                            <div className={styles.content}>Autonomy: {result.Score.Autonomy}</div>
                            <div className={styles.content}>Creativity: {result.Score.Creativity}</div>
                            <div className={styles.content}>Financial Reward: {result.Score["Financial Reward"]}</div>
                            <div className={styles.content}>Influence: {result.Score.Influence}</div>
                            <div className={styles.content}>Performance: {result.Score.Performance}</div>
                            <div className={styles.content}>Prestige: {result.Score.Prestige}</div>
                            <div className={styles.content}>Security: {result.Score.Security}</div>
                            <div className={styles.content}>Self-Development: {result.Score["Self-Development"]}</div>
                            <div className={styles.content}>Structure: {result.Score["Structure"]}</div>
                            <div className={styles.content}>Variety: {result.Score["Variety"]}</div>
                            <div className={styles.content}>Work Conditions: {result.Score["Work Conditions"]}</div>
                            <div className={styles.content}>Work Life Balance: {result.Score["Work Life Balance"]}</div>
                            <div className={styles.content}>Work Relationships: {result.Score["Work Relationships"]}</div>
                        </div>
                    </div>
                    </>
                }
                </>
  )
}

export default Test4