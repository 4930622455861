import React, { useEffect } from "react";
import "./Classten.css";
import upcloud from "../../../assets/AboutUs/clouds.png";
import girl from "../../../assets/images/girl.png";
import boy from "../../../assets/images/boy.png";
import details10 from "../../../assets/images/Graduatesdetails.png";
import frame2 from "../../../assets/images/Frame2.png";
import Programs from "../Programs/Program";
import finder from "../../../assets/images/9 2.png";
import uplifter from "../../../assets/images/10 2.png";
import booster from "../../../assets/images/11 1.png";
import downpick from "../../../assets/images/12 2.png";
import cloud from "../../../assets/images/2.png";
import "react-slideshow-image/dist/styles.css";
import Carousel from "react-elastic-carousel";
import {
  CheckButton,
  selectCheck,
  selectClass,
} from "../../../feature/ToggleSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AmountForm, PROGRAMTYPE } from "../../../feature/PaymentSlice";

function Classten() {
  const dispatch = useDispatch();
  const classType = useSelector(selectClass);
  const checkbutton = useSelector(selectCheck);
  useEffect(() => {
    if (checkbutton === true) {
      dispatch(CheckButton(false));
    }
  }, []);
  const classes = [
    {
      image: finder,
      text1: "FINDER",
      text: [
        "1 Parameter Test",
        "Personalised Test Report",
        "Target Career Selection",
      ],
      fontSize: "2vh",
      alignItems: "center",
      payment: "5000",
    },
    {
      image: uplifter,
      text1: "UPLIFIER",
      text: [
        "3 Parameter Tests",
        "Partial Free Access*",
        "Group sessions + 4 One-to-One sessions",
        "Personalised Test Report",
        "Target Career Selection",
        "Education Roadmap Preparation",
        "Degree Selection Guidance",
      ],
      fontSize: "2vh",
      payment: "10000",
      // marginTop: "10px",
    },
    {
      image: booster,
      text1: "BOOSTER",
      text: [
        "6 Parameter Tests",
        "Full Free Access*",
        "Group sessions + 8 One-to-One sessions",
        "Personalised Test Report",
        "Target Career Selection",
        "Education Roadmap Preparation",
        "Degree Selection Guidance",
        "Competitive Exams Guidance",
        "Skill Development Guidance",
      ],
      fontSize: "1.8vh",
      marginTop: "0px",
      payment: "15000",
    },
  ];
  const xwidth = window.width;
  const colors = ["#892B3B", "#1D3740", "#603D13"];

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 600, itemsToShow: 2 },
    { width: 800, itemsToShow: 3 },
  ];
  return (
    <div>
      <div id="class10">
        <div id="class10-12">
          <div className="cloudup">
            <img
              src={upcloud}
              alt="topcloud"
              id="topcloud"
              style={{ width: "100%", height: "50%" }}
            ></img>
          </div>
          <br />
          <br />
          <div id="text">Class 10-12</div>
          <div className="girl">
            <img
              src={girl}
              alt="girl"
              id="girl"
              style={{ width: "50%", height: "50%" }}
            ></img>
          </div>
          <div className="detailsimg">
            <img
              src={frame2}
              alt="details"
              id="details10"
              style={{ width: "60%", height: "100%", zIndex: "10" }}
            ></img>
          </div>
          <div className="boy">
            <img
              src={boy}
              alt="boy"
              id="boy"
              style={{ width: "50%", height: "50%" }}
            ></img>
          </div>
        </div>
        <div id="showmobile">
          <div id="moblie">
            <div id="clouds">
              <img src={cloud} alt="topcloud"></img>
            </div>
            <div id="textmob">Class 10-12</div>
            <div className="details_card">
              <Carousel
                breakPoints={breakPoints}
                className="carousal_team"
                style={{ zIndex: "10" }}
              >
                {classes.map((each, index) => (
                  <div className="card detailclass_card" key={index}>
                    <img
                      src={each.image}
                      className="card-img-top head_img"
                      alt="..."
                      d-block
                      w-100
                      style={{ marginTop: "3%" }}
                    />
                    <p className="title" style={{ color: colors[index] }}>
                      {each.text1}
                    </p>
                    <div className="card-body card_body">
                      {each.text.map((text, index1) => (
                        <>
                          <p
                            className="card-text detail-text"
                            style={{
                              textAlign: "center",
                              fontSize: each.fontSize,
                              alignItems: each.alignItems,
                            }}
                          >
                            {text}
                          </p>
                        </>
                      ))}
                    </div>
                    <Link
                      className="down_img"
                      to="/payment"
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        onClick={() => dispatch(AmountForm(each.payment))}
                        className="down_img"
                        value="Pay 1"
                        text="Pay 1"
                      >
                        Pay {each.payment}
                      </button>
                    </Link>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="payment_buttens1">
        <div className="programm_paydiv1">
          <Link to="/payment1" style={{ textDecoration: "none" }}>
            <br />
            <button
               onClick={() => {
                dispatch(AmountForm("5000"));
                dispatch(PROGRAMTYPE("FINDER"));
              }}
              className="programm_pay1"
              value="Pay 1"
              text="Pay 1"
            >
              Pay 5000
            </button>
          </Link>
          <Link to="/payment1" style={{ textDecoration: "none" }}>
            <br />
            <button
                onClick={() => {
                  dispatch(AmountForm("10000"));
                  dispatch(PROGRAMTYPE("UPLIFTER"));
                }}
              className="programm_pay1"
              value="Pay 1"
              text="Pay 1"
            >
              Pay 10000
            </button>
          </Link>
          <Link to="/payment1" style={{ textDecoration: "none" }}>
            <br />
            <button
               onClick={() => {
                dispatch(AmountForm("15000"));
                dispatch(PROGRAMTYPE("BOOSTER"));
              }}
              className="programm_pay1"
              value="Pay 1"
              text="Pay 1"
            >
              Pay 15000
            </button>
          </Link>
        </div>
      </div>
      <div>
        <Programs />
      </div>
    </div>
  );
}

export default Classten;
