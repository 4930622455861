import { createSlice } from '@reduxjs/toolkit'

export const toggleSlice = createSlice({
  name: 'toggle',
  initialState: {
    toggle: false,
    class:"",
    check:false,
  },
  reducers: {
    ToggleTheForm: (state, action) => {
      state.toggle = action.payload; 
    },
    ClassType: (state, action) => {
      state.class = action.payload; 
    },
    CheckButton: (state, action) => {
      state.check = action.payload; 
    },
  },
})

// Action creators are generated for each case reducer function
export const { ToggleTheForm ,ClassType,CheckButton} = toggleSlice.actions

export const selectToggle = (state) => state.toggle.toggle;
export const selectClass = (state) => state.toggle.class;
export const selectCheck = (state) => state.toggle.check;

export default toggleSlice.reducer