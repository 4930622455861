import React,{useState,useEffect} from 'react'
import getStoredStateMigrateV4 from 'redux-persist/lib/integration/getStoredStateMigrateV4';
import { db } from "../../../src/firebaseTools";
import './Blog.css'
import {useHistory} from 'react-router-dom'
import Footerbody from '../../components/home/FooterBody/Footerbody'

import Top from '../../assets/images/Top.png'
import Right from '../../assets/images/Right.png'
import Left from '../../assets/images/Left.png'

function Blog() {
    const [blogs,setBlogs] = useState([])
    function getData(){
        db.collection("blogs").orderBy("createdAt").limit(8).onSnapshot((querySnapshot)=>{
            const items = []
            querySnapshot.forEach((doc) => {
                items.push(doc.data())
            })
            setBlogs(items)
        })
    }

    useEffect(()=>{
        getData()
    },[])

    const history = useHistory()

    function openBlog(data){
        history.push('/blogonclick', {state: data})
    }

    return (
        <div>      

            {
                blogs.slice(0,1).map(item => {

                    var data = {
                        title: item["title"],
                        subtitle: item["subtitle"],
                        imageUrl: item["imgUrl"],
                        tags: item["keywords"],
                        content: item["widgetTree"]
                    }

                    return (
                        <div>
                            <img src={data["imageUrl"]} style={{
                                objectFit:'cover',
                                width:'100%',
                                height:'60vh',
                                marginBottom:'15vh',
                                position:'absolute',
                                top:'0'
                            }}/>
                            <div className='top-inner-container'>
                                <div style={{fontWeight:'bold',fontSize:'3vh'}}>{data["title"]}</div>
                                <div style={{margin:'1vh 0'}}>hello world</div>
                                <div onClick={()=>openBlog(data)} style={{background:'blue',color:'white',padding:'1vh 2vw',display:'inline-block',fontWeight:'bold',cursor:'pointer'}}>Read More</div>
                            </div>
                        </div>
                    )
                })
            }

            <img src={Top} style={{zIndex:'-1',position:'absolute',top:'-20vh',left:'25%'}} />
            <img src={Right} style={{zIndex:'-1',position:'absolute',top:'130vh',right:'0'}} />
            <img src={Left} style={{zIndex:'-1',position:'absolute',top:'180vh',left:'0'}} />

            <div style={{
                width:'70%',
                margin:'65vh auto',
                marginBottom:'0'
            }}>
                <div style={{
                margin:'2vh 0',
                fontSize:'4vh',
                fontWeight:'bold',
                color:'blue'
                }}>
                    Top Picks in Career
                </div>

                <div style={{
                    fontSize:'2.2vh',
                    color:'grey',
                }}>
                    Change your Career Today. Here are our best picks.
                </div>

                <div className='top-picks-container'>
                {
                    blogs.slice(0,3).map(item => {

                        var data = {
                            title: item["title"],
                            subtitle: item["subtitle"],
                            imageUrl: item["imgUrl"],
                            tags: item["keywords"],
                            content: item["widgetTree"]
                        }

                        return (
                            <div className='top-picks-item' onClick={()=>openBlog(data)}>
                                <img src={data["imageUrl"]} width="100%" style={{objectFit:'cover'}}/>
                                <div style={{marginTop:'2vh',fontSize:'2.2vh'}}>{data["title"]}</div>
                                <div style={{fontSize:'2vh',color:'grey',display:'flex'}}>
                                        {
                                            data["tags"].map(tag => {
                                                return (
                                                    <p>{tag}</p>
                                                )
                                            })
                                        }
                                    </div>
                            </div>
                        )
                    })
                }
                </div>

                <hr style={{margin:'5vh 0',color:'blue'}}/>

                <div style={{
                margin:'2vh 0',
                fontSize:'4vh',
                fontWeight:'bold',
                color:'blue'
                }}>
                    Recent Posts
                </div>

                <div className='blogs-container'>
                {
                    blogs.slice(0,8).map(item => {

                        var data = {
                            title: item["title"],
                            subtitle: item["subtitle"],
                            imageUrl: item["imgUrl"],
                            tags: item["keywords"],
                            content: item["widgetTree"]
                        }

                        return (
                            <div className='blog-container' onClick={()=>openBlog(data)}>
                                <div className='blog-container1'>
                                    <div className='blog-container1-title'>{data["title"]}</div>
                                    <div className='blog-container1-subtitle'>{data["subtitle"]}</div>
                                    <div className='blog-container1-tags'>
                                        {
                                            data["tags"].map(tag => {
                                                return (
                                                    <p>{tag}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            <img src={data["imageUrl"]} width="43%" style={{objectFit:'cover'}}/>

                            </div>
                        )
                    })
                }
                </div>
            </div>
            <Footerbody/>
        </div>
    )
}

export default Blog
