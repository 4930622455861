import Navbar from "./Navbar/navbar";
import Bodyfirst from "./Body/Body";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import MessengerCustomerChat from "react-messenger-customer-chat";
import AboutUs from "./NewAboutUs/AboutUs";
import Payment from "./PaymentGateway/Payment";
import Classsix from "./ClassSix/Classsix";
import Classten from "./ClassTen/Classten";
import Classgraduates from "./ClassGraduates/Classgraduates";
import Payment1 from "./PaymentGateway1/Payment1";
import Payment2 from "./PaymentGateway2/Payment2";
import { Pay } from "twilio/lib/twiml/VoiceResponse";
import Process from "./FivestepProcess/Process";
import Blog from "../Blog/Blog";
import BlogOnClick from "../BlogOnClick/BlogOnClick";
import Application from "../Application/Application";
import Main from "../Tests/Main";
import Test from "../Tests/Test";
import RegistrationForm from "../Registration/RegistrationForm";
import TestResult from "../Tests/TestResult";
import { useEffect, useState } from "react";


function Home() {
  const [changeLogin,setChangeLogin] = useState(false)

  console.log(changeLogin)

  return (
    <div>
      <Router>
        <Navbar changeLogin={changeLogin} />
        <MessengerCustomerChat
          pageId="105603081235342"
          appId="362859328750727"
        />
        <Switch>
          <Route path="/Classsix">
            <Classsix />
          </Route>
          <Route path="/Classten">
            <Classten />
          </Route>
          <Route path="/Classgraduates">
            <Classgraduates />
          </Route>
          <Route exect path="/aboutus">
            <AboutUs />
          </Route>
          <Route exect path="/application">
            <Application />
          </Route>
          <Route exact path="/tests">
            <Main setChangeLogin={setChangeLogin} />
          </Route>
          <Route exact path="/register">
            <RegistrationForm/>
          </Route>
          <Route exact path="/result">
            <TestResult/>
          </Route>
          <Route exact path="/test/:endpoint">
            <Test/>
          </Route>
          <Route exect path="/blogs">
            <Blog />
          </Route>
          <Route exect path="/blogonclick">
            <BlogOnClick />
          </Route>
          <Route path="/home">
            <Bodyfirst />
          </Route>
          <Route path="/payment">
            <Payment />
          </Route>
          <Route path="/payment1">
            <Payment1 />
          </Route>
          <Route path="/payment2">
            <Payment2 />
          </Route>
          <Route path="/">
            <Bodyfirst />
          </Route>
          {
            <MessengerCustomerChat
              pageId="105603081235342"
              appId="362859328750727"
            />
          }
        </Switch>
      </Router>
    </div>
  );
}
export default Home;
