import React,{useState,useEffect} from 'react'
import styles from '../TestResult.module.css'

function Test6({result}) {
    const [key,setKey] = useState(null)

    useEffect(()=>{
        if(result && result.Analysis){
            setKey(Object.keys(result.Analysis).toString())
        }
    },[result])

    console.log(key)

  return (
    <>
            {
                result && 
                <>
                {
                    result['Score'] &&
                    <div className={styles.section}>
                        <div className={styles.Title}>Score</div>
                        <div>
                            <div className={styles.content}>Auditory Learner: {result['Score']['Auditory Learner'] && result['Score']['Auditory Learner']}</div>
                            <div className={styles.content}>Kinesthetic Learner: {result['Score']['Kinesthetic Learner'] && result['Score']['Kinesthetic Learner']}</div>
                            <div className={styles.content}>Visual Learner: {result['Score']['Visual Learner'] && result['Score']['Visual Learner']}</div>
                        </div>
                    </div>
                }

                {
                    result['Attribute'] &&
                    <div className={styles.section}>
                        <div className={styles.Title}>Attribute</div>
                        <div className={styles.content}>{result['Attribute'] && result['Attribute']}</div>
                    </div>
                }
    

                {
                    result.Analysis &&
                    <div className={styles.section}>
                        <div className={styles.Title}>Analysis</div>
                        <div className={styles.content}>{key}</div>
                        {
                        result.Analysis[key] && result.Analysis[key].Advice &&
                        <div className={styles.section}>
                            <div className={styles.subTitle}>Advice</div>
                            <div>
                                {
                                    result.Analysis[key] && result.Analysis[key].Advice && result.Analysis[key].Advice.map(item => {
                                        return (
                                            <div className={styles.content}>{item}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        }

                        {                       
                        result.Analysis[key] && result.Analysis[key]["Brief explanation"] &&
                        <div className={styles.section}>
                            <div className={styles.subTitle}>Brief Explanation</div>
                            <div>
                                {
                                    result.Analysis[key] && result.Analysis[key]["Brief explanation"] && result.Analysis[key]["Brief explanation"].map(item => {
                                        return (
                                            <div className={styles.content}>{item}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        }

                        {
                        result.Analysis[key] && result.Analysis[key].Characteristics &&
                        <div className={styles.section}>
                            <div className={styles.subTitle}>Characteristics</div>
                            <div>
                                {
                                    result.Analysis[key] && result.Analysis[key].Characteristics && result.Analysis[key].Characteristics.map(item => {
                                        return (
                                            <div className={styles.content}>{item}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        }
                    </div>
                }
                </>
            }
            </>
  )
}

export default Test6