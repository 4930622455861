import Logo from "../../../assets/images/logo.png";
import "./navbar.css";
import fbicon from "../../../assets/images/facebook.svg";
import instagram from "../../../assets/images/insta1.png";
import lnicon from "../../../assets/images/linkedin.svg";
import "../../../fontawesome-free-5.13.1-web/css/all.css";
import Icon from "../../home/social/fbcomp";

import ApplePlay from "../../../assets/images/contact_apple.svg";
import GooglePlay from "../../../assets/images/contact_google.svg";
import { db } from "../../../firebaseTools";
import { Popedprogram } from "../Programs/Program";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { color } from "@mui/system";
import { useDispatch } from "react-redux";
import { ToggleTheForm } from "../../../feature/ToggleSlice";
import $ from "jquery";
import {useHistory} from 'react-router-dom'

function Nav({changeLogin}) {
  let [toggle, settoggle] = useState(1);
  const dispatch = useDispatch();

  const history = useHistory()

  const xwidth = window.screen.width;

  function Popover() {
    settoggle(!toggle);
    if (toggle == 1) {
      dispatch(ToggleTheForm(true));
      document.getElementById("popover").style.display = "block";
    }
    if (toggle == 0) {
      dispatch(ToggleTheForm(false));
      document.getElementById("popover").style.display = "none";
    }
  }

  function closeprogram() {
    document.getElementById("popup").style.display = "none";
    document.getElementById("popup").style.zIndex = "0";
  }

  return (
    <div>

      <nav
        onLoad={() =>Popover()}
        className="navbar navbar-expand-lg navbar-light"
      >
        <div className="container-fluid" style={{ zIndex: "12"}}>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <a
              onClick={() => {
                if (xwidth <= 990) {
                  $(".navbar-collapse").hide(500, "linear");
                }
                closeprogram();
              }}
              id=""
              className="navbar-brand"
              href=""
            >
              <img id="firstlogo" src={Logo} alt="company-logo" />
            </a>
          </Link>

          <button
            style={{ zIndex: "12" }}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            autofocus
            onClick={() => $(".navbar-collapse").show("slide")}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div
            id="nav-main"
            className="collapse navbar-collapse"
            // id="navbarSupportedContent"
            onClick={() => {
              if (xwidth <= 990) {
                $(".navbar-collapse").toggle(500, "linear");
              }
            }}
            style={{
            }}
          >
            <ul 
            // className="navbar-nav me-auto mb-2 mb-lg-0"
            style={{
              textDecoration:'none',
              listStyle:'none',
              display:'flex',
              position:'absolute',
              right:'0'
            }}
            >

              {
                changeLogin ?
                <li style={{ zIndex: "12"}}>
                <div onClick={()=>{
                  sessionStorage.removeItem('user')
                  window.location.reload()
                }} style={{ textDecoration: "none" }}>
                  <a
                    id="i"
                    className="nav-link"
                    href=""
                    style={{ textDecoration: "none" }}
                  >
                    Logout
                  </a>
                </div>
              </li>
                :
                <li style={{ zIndex: "12"}}>
                <Link to="/register" style={{ textDecoration: "none" }}>
                  <a
                    id="i"
                    className="nav-link"
                    href=""
                    style={{ textDecoration: "none" }}
                  >
                    Login
                  </a>
                </Link>
              </li>
              }
              
              <li className="nav-item ">
                <a
                  id="i"
                  className="nav-link"
                  href=""
                  onMouseEnter={() => {
                    Popedprogram();
                  }}
                >
                  Programs
                </a>
              </li>

              {/* <li style={{ zIndex: "12"}}>
                <Link to="/application" style={{ textDecoration: "none" }}>
                  <a
                    id="i"
                    className="nav-link"
                    href=""
                    style={{ textDecoration: "none" }}
                  >
                    Application
                  </a>
                </Link>
              </li> */}
            
              {/* <li style={{ zIndex: "12"}}>
                <Link to="/blogs" style={{ textDecoration: "none" }}>
                  <a
                    id="i"
                    className="nav-link"
                    href=""
                    style={{ textDecoration: "none" }}
                  >
                    Blogs
                  </a>
                </Link>
              </li> */}

              {/* <li className="nav-item" style={{ zIndex: "12" }}>
                <Link to="/aboutus" style={{ textDecoration: "none" }}>
                  <a
                    id="i"
                    className="nav-link"
                    href=""
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      closeprogram();
                    }}
                  >
                    About us
                  </a>
                </Link>
              </li> */}

                    {/* mobile get-in-touch */}
              <li className="nav-item" id="hide">
                <a
                  onClick={() => {
                    Popover();
                  }}
                  id="i"
                  className="nav-link"
                  href=""
                >
                  Get in Touch
                </a>
              </li>


                  {/* desktop get-in-touch */}
              <li style={{ zIndex: "12",margin:'0',paddingTop:'1.5vh'}}
              onClick={() => {
                Popover();
              }}
              id="butt"
              className="btn"
              type="submit"
            >
              Get in Touch
            </li>

              {/* <li style={{ zIndex: "12"}}>
                <Link to="/register" style={{ textDecoration: "none" }}>
                  <a
                    id="i"
                    className="nav-link"
                    href=""
                    style={{ textDecoration: "none" }}
                  >
                    Register
                  </a>
                </Link>
              </li> */}
            </ul>

            {/* <button
              onClick={() => {
                Popover();
              }}
              id="butt"
              className="btn"
              type="submit"
            >
              Get in Touch
            </button> */}

            <div id="socialHide" style={{ padding: "1rem" }}>
              <a href="https://www.facebook.com/infigonfutures">
                <Icon Icon={fbicon} />
              </a>
              <a href="https://www.instagram.com/infigon_futures/">
                <span>
                  <img
                    src={instagram}
                    alt="instalogo"
                    id="instalogo"
                    style={{ height: "60px", width: "60px" }}
                  ></img>
                </span>
              </a>
              <a href="https://www.linkedin.com/company/infigon-futures">
                <Icon Icon={lnicon} />
              </a>
            </div>            
            <ContactHide />

          </div>
        </div>
      </nav>

      <div id="popover" style={{ width: "100%" }}>
        <Pop fn={Popover} />
      </div>
    </div>
  );
}

function ContactHide() {
  return (
    <>
      <div id="contactHide">
        <ul style={{ listStyleType: "none" }}>
          <h5>Contact us</h5>
          <li>
            <a>
              <i className="fas fa-map-pin"></i> 2nd Floor, SP-TBI, Andheri
              West, Mumbai - 400053
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="mailto:info@infigonfutures.com"
            >
              <i className="far fa-envelope"></i> Email :
              info@infigonfutures.com
            </a>
          </li>
          <li>
            <a
              href="tel:8016216564"
              style={{ textDecoration: "none", color: "black" }}
            >
              <i className="fas fa-phone-alt"></i> +91 7208199462
            </a>
          </li>
        </ul>
        <span>
          <a
            href="https://play.google.com/store/apps/details?id=com.tech.infigon"
            target="_blank"
          >
            <img src={GooglePlay}></img>
          </a>
        </span>
        <span>
          <a
            href="https://apps.apple.com/in/app/infigon-the-career-mentor/id1564255604"
            target="_blank"
          >
            <img src={ApplePlay}></img>
          </a>
        </span>
      </div>
    </>
  );
}

export function ContactHide2() {
  return (
    <>
      <div id="contactHideduplicate">
        <ul style={{ listStyleType: "none" }}>
          <h5>Contact us</h5>
          <li>
            <a>
              <i className="fas fa-map-pin"></i> Address ipsum dolor sit amet,
              consectetuer adipiscing elit, sed diam nonummy
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="mailto:ipsumdolor@gmail.com"
            >
              <i className="far fa-envelope"></i> Email : ipsumdolor@gmail.com
            </a>
          </li>
          <li>
            <a
              href="tel:8016216564"
              style={{ textDecoration: "none", color: "black" }}
            >
              <i className="fas fa-phone-alt"></i> +91 87449 87449
            </a>
          </li>
        </ul>
        <span>
          <Link to="https://www.google.com">
            <img src={GooglePlay}></img>
          </Link>
        </span>
        <span>
          <a href="https://infigon.page.link/2f6j4DqAp1Uqz7427">
            <img src={ApplePlay}></img>
          </a>
        </span>
      </div>
    </>
  );
}

export function Pop(prop) {
  const [Info, setInfo] = useState({
    Name: "",
    YouAre: "",
    Stanadard: "",
    Board: "",
    Location: "",
    Query: "",
    Email: "",
    Mob: "",
  });

  async function DirectToFirebase() {
    db.collection("BookSession").add({
      Name: Info.Name,
      HeIs: Info.YouAre,
      Standard: Info.Stanadard,
      Board: Info.Board,
      Location: Info.Location,
      Query: Info.Query,
      Email: Info.Email,
      Mobile: Info.Mob,
    });
    const res = await fetch(
      "https://infigondb-default-rtdb.firebaseio.com/Infigonform.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Name: Info.Name,
          HeIs: Info.YouAre,
          Standard: Info.Stanadard,
          Board: Info.Board,
          Location: Info.Location,
          Query: Info.Query,
          Email: Info.Email,
          Mobile: Info.Mob,
        }),
      }
    );

    window.alert("Our representative will contact you soon");
    window.location.reload();
  }
  

  return (
    <div
      style={{
        width: "100%",
      }}
      id="popcss"
    >
      <form style={{ width: "100%" }}>
        <div id="popbody">
          <br />
          <h3>
            Get In Touch{" "}
            <i
              onClick={() => {
                prop.fn();
              }}
              className="far fa-times-circle"
            ></i>
          </h3>

          <div class="mb-1">
            <label for="exampleInputEmail1" class="form-label">
              Name
            </label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setInfo({ ...Info, Name: e.target.value });
              }}
              required
            />
          </div>
          <div class="mb-1">
            <label for="exampleInputEmail1" class="form-label">
              You are a :
            </label>

            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setInfo({ ...Info, YouAre: e.target.value });
              }}
              required
            >
              <option selected>You are</option>
              <option value="Student">Student</option>
              <option value="Parent">Parent</option>
            </select>
          </div>
          <div class="mb-1">
            <label for="exampleInputEmail1" class="form-label">
              Standard(School) / Year of Study(College)
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setInfo({ ...Info, Stanadard: e.target.value });
              }}
              required
            />
          </div>
          <div class="mb-1">
            <label for="exampleInputEmail1" class="form-label">
              Board(School) / Degree(College)
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setInfo({ ...Info, Board: e.target.value });
              }}
              required
            />
          </div>
          <div class="mb-1">
            <label for="exampleInputEmail1" class="form-label">
              Contact Number)
            </label>
            <input
              type="tel"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setInfo({ ...Info, Mob: e.target.value });
              }}
              required
            />
          </div>
          <div class="mb-1">
            <label for="exampleInputEmail1" class="form-label">
              Email ID
            </label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => {
                setInfo({ ...Info, Email: e.target.value });
              }}
              required
            />
          </div>

          <div class="mb-1">
            <label for="exampleInputPassword1" class="form-label">
              Location (City - State)
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              onChange={(e) => {
                setInfo({ ...Info, Location: e.target.value });
              }}
              required
            />
          </div>
          <div class="mb-1">
            <label for="exampleInputPassword1" class="form-label">
              Any specific queries?
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              onChange={(e) => {
                setInfo({ ...Info, Query: e.target.value });
              }}
              required
            />
          </div>
          <br />

          <button
            type="submit"
            style={{ marginLeft: "35%", marginBottom: "2%" }}
            onClick={(e) => {
              e.preventDefault();
              DirectToFirebase();
            }}
            class="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Nav;
