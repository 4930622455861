import React from 'react'
import {useLocation} from 'react-router-dom'
import './BlogOnClick.css'
import {FaLinkedinIn,FaTwitter,FaFacebookF} from 'react-icons/fa'

import {LinkedinShareButton,TwitterShareButton,FacebookShareButton} from 'react-share'
import {LinkedinIcon,TwitterIcon,FacebookIcon} from 'react-share'

function BlogOnClick() {
    const location = useLocation()
    var data = location.state.state

    console.log(document.location.href)

    return (
        <div className='blogonclick'>
            <div className='left'>
                <div className='left-container'>
                    <div className='share'>Share</div>
                    <LinkedinShareButton url='http://localhost:3000/blogonclick'>
                        <LinkedinIcon round={true}></LinkedinIcon>
                    </LinkedinShareButton>
                    <TwitterShareButton url='http://localhost:3000/blogonclick'>
                        <TwitterIcon round={true}></TwitterIcon>
                    </TwitterShareButton>
                    <FacebookShareButton url='http://localhost:3000/blogonclick'>
                        <FacebookIcon round={true}></FacebookIcon>
                    </FacebookShareButton>
                </div>
            </div>

            <div className='middle'>
                <div className='middle-title'>{data["title"]}</div>
                <img src={data["imageUrl"]} className='middle-image' />

                {
                    data["content"].slice(1).map(item => {

                        var text;
                        var weight;
                        var color;
                        var size;
                        if( item.hasOwnProperty("text")){
                            text = item["text"]["text"]
                            weight = item["text"]["weight"]
                            color = item["text"]["color"]
                            size = item["text"]["size"]
                        }

                        return (
                            <div style={{margin:'3vh 0',fontWeight:`${weight}`,color:`${color}`,fontSize:`${size}px`}}>{text}</div>
                        )
                    })
                }

            </div>

            <div className='right'>
                <div className='right-container'>
                    <div className='morelikethis'>More Like This</div>
                    <div className='more-container'>
                        <div className='more-blog'>
                            <div className='more-blog-container-1'>
                                <div className='more-blog-1-title'>{data["title"]}</div>
                                <div className='more-blog-1-content'>hello and bye</div>
                            </div>
                            <img src={data["imageUrl"]} className='more-blog-container-2'/>
                        </div>
                        <div className='more-blog'>
                            <div className='more-blog-container-1'>
                                <div className='more-blog-1-title'>{data["title"]}</div>
                                <div className='more-blog-1-content'>hello and bye</div>
                            </div>
                            <img src={data["imageUrl"]} className='more-blog-container-2'/>
                        </div>
                        <div className='more-blog'>
                            <div className='more-blog-container-1'>
                                <div className='more-blog-1-title'>{data["title"]}</div>
                                <div className='more-blog-1-content'>hello and bye</div>
                            </div>
                            <img src={data["imageUrl"]} className='more-blog-container-2'/>
                        </div>
                        <div className='more-blog'>
                            <div className='more-blog-container-1'>
                                <div className='more-blog-1-title'>{data["title"]}</div>
                                <div className='more-blog-1-content'>hello and bye</div>
                            </div>
                            <img src={data["imageUrl"]} className='more-blog-container-2'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogOnClick
