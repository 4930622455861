import React, {useState,useEffect} from 'react';
import styles from './RegistrationForm.module.css';
import { db } from "../../firebaseTools.js";
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {BsCheckCircle} from 'react-icons/bs'
import {useHistory} from 'react-router-dom'
import ClipLoader from "react-spinners/ClipLoader";
import Login from '../Login/Login';

const schema = yup.object({
  Name : yup.string().required(),
  // Email : yup.string().email().required(),
  Contact : yup.string().length(10).required(),
  // Age : yup.number().min(1).max(60).positive().integer().required(),
  // Profession : yup.string().required() 
  Class : yup.string().required(),
  School: yup.string().required(),
  SchoolCode : yup.string().required(),
  Password : yup.string().required(),
  ConfirmPassword : yup.string().oneOf([yup.ref('Password'), null],'Passwords must match')
}).required()

 
const RegisterationForm = () => {
  const history = useHistory() 

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  useEffect(()=>{
    const user = sessionStorage.getItem('user')
    if(user){
        history.push('/tests')
    }
  },[])

  const [success,setSuccess] = useState(false)
  const [registerLoader,setRegisterLoader] = useState(false)

  const {register,handleSubmit,formState:{errors}} = useForm({
    resolver : yupResolver(schema)
  })

  const onSubmit = (data) => {
    console.log(data)
    setRegisterLoader(true)

    db.collection('PromoCode').doc(data.SchoolCode).get()
    .then(doc => {
      if(doc.exists){
        db.collection("contacts").doc(data.Contact).get()
        .then(doc => {
          if(doc.exists){
            setRegisterLoader(false)
            return alert('Contact already exists')
          }else {
            db.collection("contacts").doc(data.Contact).set(data)
            .then(() => {
                setRegisterLoader(false)
              setSuccess(true)
              sessionStorage.setItem('user',JSON.stringify(data))
              setTimeout(()=>{
                history.push('/tests')
              },2000)
            })
            .catch((error) => {
              alert(error.message);
            });
          }
        })
      }
      else {
        alert('School Code is not valid')
        setRegisterLoader(false)
      }
    })
    .catch(err => console.log(err.message))

    // db.collection("contacts").doc(data.Contact).set(data)
    // .then(() => {
    //     setRegisterLoader(false)
    //   setSuccess(true)
    //   sessionStorage.setItem('user',JSON.stringify(data))
    //   setTimeout(()=>{
    //     history.push('/tests')
    //   },2000)
    // })
    // .catch((error) => {
    //   alert(error.message);
    // });

    // window.scrollTo(0,0)
  }

  return (
    <div style={{display:'flex'}}>
      <Login/>

    <div className={styles.formContainer}> 
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {
        success &&
        <div className={styles.modalContainer}>
            <div style={{
                    width:'fit-content',
                    textAlign:'center',
                    padding:'8vh 10vw',
                    color:'#2f3679',
                    position:'absolute',
                    background:'white',
                    top:'20%',
                    left:'0',
                    right:'0',
                    margin:'auto'
                }}>
                    <BsCheckCircle size={70} color='green' />
                    <div style={{
                        fontFamily:'Poppins',
                        fontSize:'2.8vh',
                        marginTop:'2vh',
                        fontWeight:'bold'
                        }}>
                        Registration successful
                    </div>
                    <br/>
                    <div style={{fontFamily:'Poppins',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div style={{marginRight:'0.5vw'}}>Redirecting</div>
                        <ClipLoader color='black' loading={true} size={20} />
                    </div>
            </div>  
        </div> 
      }

    {
        registerLoader &&
        <div className={styles.modalContainer}>
           <div style={{
                    width:'fit-content',
                    position:'absolute',
                    top:'50%',
                    transform:'translateY(-50%)',
                    left:'0',
                    right:'0',
                    margin:'auto'
                }}>
                    <ClipLoader color='white' loading={true} size={60} />
            </div>
        </div> 
      }

        <>

        <div style={{textAlign:'center',fontFamily:'Poppins',fontWeight:'bold',fontSize:'3vh',color:'#2f3679'}}>
            Register Here
        </div> 

        <div className={styles.formItem}>
        <label className={styles.label}>Full name <span style={{color:'red'}}>*</span></label>
        <input type="text" className={styles.formInput} placeholder="Enter full name" name="Name" 
        {...register("Name")}/>
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Name?.message}</div>
        </div>

        {/* <div className={styles.formItem}>
        <label  className={styles.label}>Email <span style={{color:'red'}}>*</span></label>
        <input type="text" className={styles.formInput} placeholder="Enter email address" name="Email" 
        {...register("Email")}/>
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Email?.message}</div>
        </div> */}

        <div className={styles.formItem}>
        <label  className={styles.label}> Contact Number <span style={{color:'red'}}>*</span> </label>
        <input type="text" className={styles.formInput} placeholder="Enter phone number" name="Contact" 
        {...register("Contact")}/>
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Contact?.message}</div>
        </div>

        <div className={styles.formItem}>
        <label  className={styles.label}> Class <span style={{color:'red'}}>*</span> </label>
        <select name="Class" className={styles.formInput} {...register("Class")}>
          <option value="">Select class</option>
          <option value="6" >6</option>
          <option value="7" >7</option>
          <option value="8" >8</option>
          <option value="9" >9</option>
          <option value="10" >10</option>
          <option value="11" >11</option>
          <option value="12" >12</option>
        </select>
        {/* <input type="text" className={styles.formInput} placeholder="Enter class" name="Class" 
        {...register("Class")}/> */}
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Class?.message}</div>
        </div>

        <div className={styles.formItem}>
        <label className={styles.label}> School <span style={{color:'red'}}>*</span> </label>
        <select name="School" className={styles.formInput} {...register("School")}>
          <option value="">Select school</option>
          <option value="S.C.D. Barfiwala High School">S.C.D. Barfiwala High School</option>
        </select>
        {/* <input type="text" className={styles.formInput} placeholder="Enter school" name="School" 
        {...register("School")}/> */}
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.School?.message}</div>
        </div>

        <div className={styles.formItem}>
        <label className={styles.label}> School Code <span style={{color:'red'}}>*</span> </label>
        {/* <select name="SchoolCode" className={styles.formInput} {...register("SchoolCode")}>
          <option value="">Select school code</option>
          <option value="1" >1</option>
          <option value="2" >2</option>
          <option value="3" >3</option>
        </select> */}
        <input type="text" className={styles.formInput} placeholder="Enter school code" name="SchoolCode" 
        {...register("SchoolCode")}/>
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.SchoolCode?.message}</div>
        </div>

        <div className={styles.formItem}>
        <label  className={styles.label}> Password <span style={{color:'red'}}>*</span> </label>
        <input type="text" className={styles.formInput} placeholder="Enter password" name="Password" 
        {...register("Password")}/>
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Password?.message}</div>
        </div>

        <div className={styles.formItem}>
        <label  className={styles.label}> Confirm Password <span style={{color:'red'}}>*</span> </label>
        <input type="text" className={styles.formInput} placeholder="Enter confirm password" name="ConfirmPassword" 
        {...register("ConfirmPassword")}/>
        <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.ConfirmPassword?.message}</div>
        </div>

        <div className={styles.formItem}>
            {/* <label className={styles.label}> Age <span style={{color:'red'}}>*</span></label>
            <input type="text" className={styles.formInput} placeholder="Enter age" name="Age" 
            {...register("Age")}/>
            <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Age?.message.slice(0,27)}</div> */}

            {/* <div className={styles.formItem}>
                <label className={styles.label}> Profession <span style={{color:'red'}}>*</span></label>
                <input type="text" className={styles.formInput} placeholder="Enter profession" name="Profession" 
                {...register("Profession")}/>
                <div style={{fontSize:'2vh',color:'red',marginTop:'0.5vh'}}>{errors.Profession?.message}</div>
            </div> */}

            <div className={styles.formItem}>
                <input className={styles.formButton} type="submit"/>
            </div>
        </div>

        </>
    </form>
  </div>
    </div>
  );
};

export default RegisterationForm;