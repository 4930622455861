import { configureStore } from "@reduxjs/toolkit";
import ToggleReducer from "../feature/ToggleSlice";
import PaymentReducer from "../feature/PaymentSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


 const persistConfig = {
   key: "root",
   storage: storage,
 };

 const paymentReducer = persistReducer(persistConfig, PaymentReducer);

export const store = configureStore({
  reducer: {
    toggle: ToggleReducer,
    payment: paymentReducer,
  },
});

 export const persistor = persistStore(store);
