import React from "react";
import { useState, useEffect } from "react";
import "./Program.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ClassType } from "../../../feature/ToggleSlice";
import Button from "@mui/material/Button";
import $ from "jquery";

export const Popedprogram = () => {
  document.getElementById("popup").style.display = "block";
  document.getElementById("popup").style.zIndex = "20";
};

function closeprogram() {
  document.getElementById("popup").style.display = "none";
  document.getElementById("popup").style.zIndex = "0";
}

function Program() {
  const dispatch = useDispatch();

  return (
    <div>
      <div
        id="popup"
        onMouseLeave={() => {
          closeprogram();
        }}
      >
        <div id=" programcontent">
          <div id="popprogram">
            <div className="close"></div>
            {/* <Link to="/home" style={{ textDecoration: "none" }}> */}
            <a href="#" style={{ color: "black" }}>
              <i
                class="far fa-times-circle"
                onClick={() => {
                  closeprogram();
                  $(".navbar-collapse").toggle(1000, "linear");
                }}
                style={{ fontSize: "25px" }}
              ></i>
            </a>
            {/* </Link> */}
          </div>
          <br />
          <br />
          <div id="programbody">
            <div className="firstpart">
              <p className="details">
                <h4>Class 6-9</h4>
                <br />
                Detailed self-analysis tests with personalized reports.
                Handholding for education path preparation, subject selection,
                competitive examinations and much more!
                <br />
                <Link to="/Classsix" style={{ textDecoration: "none" }}>
                  <a
                    href="#"
                    id="class6"
                    style={{ border: "none", outline: "none" }}
                    onClick={() => {
                      closeprogram();
                      $(".navbar-collapse").toggle(1000, "linear");
                    }}
                  >
                    Read More...
                  </a>
                </Link>
              </p>

              <p className="details">
                <h4>Class 10-12</h4>
                <br />
                Tests for shortlisting career options along with selection of
                degree and support for end-to-end admissions procedure. Also,
                develop your skills with personalized mentorship and much more!
                <br />
                <Link to="/Classten" style={{ textDecoration: "none" }}>
                  <a
                    href="closeprogram();"
                    id="class6"
                    style={{ border: "none", outline: "none" }}
                    onClick={() => {
                      closeprogram();
                      $(".navbar-collapse").toggle(1000, "linear");
                    }}
                  >
                   Read More...
                  </a>
                </Link>
              </p>

              <p className="details">
                <h4>Graduates</h4>
                <br /> Analyze your personality, select a career, develop your
                resume and skills through internships and competitions under
                expert mentorship and much more!
                <br />
                <br/>
                <Link to="/ClassGraduates" style={{ textDecoration: "none" }}>
                  <a
                    href="closeprogram();"
                    id="class6"
                    style={{ border: "none", outline: "none" }}
                    onClick={() => {
                      closeprogram();
                      $(".navbar-collapse").toggle(1000, "linear");
                    }}
                  >
                    Read More...
                  </a>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Program;
