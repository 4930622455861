import React,{useEffect,useState} from 'react'
import styles from './Main.module.css'
import testSVG from '../../assets/images/testIcon.svg'
import {useHistory} from 'react-router-dom'
import { data } from 'jquery'
import { db } from "../../firebaseTools.js";

const tests = [
    {
        name:'personality explorer',
        endpoint:'test1'
    },
    {
        name:'interest explorer',
        endpoint:'test2'
    },
    {
        name:'career explorer',
        endpoint:'test3'
    },
    {
        name:'Career motivation',
        endpoint:'test4'
    },
    {
        name:'EQ explorer',
        endpoint:'test5'
    },
    {
        name:'Learning style explorer',
        endpoint:'test6'
    }
]

function Main({setChangeLogin}) {
    const [results, setResults] = useState(null)
    const [user,setUser] = useState(JSON.parse(sessionStorage.getItem('user')))

    useEffect(()=>{
        const user = JSON.parse(sessionStorage.getItem('user'))
        console.log(user)
        if(!user){
            history.push('/register')
        }else {
            setChangeLogin(true)
        }
    },[user])

    const history = useHistory()

    useEffect(async()=>{
        if(user) {
            let userResult = db.collection('contacts').doc(user.Contact)
            userResult.get().then(function(doc) {
                if (doc.exists) {
                    const result = []
                    doc.data().result.map((item) => 
                    {
                        result.push(Object.keys(item).toString())

                    }
                    )
                    setResults(result)
                } else {
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        }
    },[user])


    console.log(results)

  return (
    <>
    <div className={styles.greet}>Hello, {user && user.Name}</div>
    <div className={styles.testGrid}>
        {
            tests.map(test => {
                let ans = results?.includes(test.endpoint)
                return (
                    <div className={styles.testItem}>
                        <img src={testSVG} alt="test-icon" className={styles.testIcon}/>
                        <div className={styles.testName}>{test.name}</div>
                        {
                            ans ?
                            <div className={styles.resultTitle} 
                            onClick={()=>history.push({
                                pathname:'/result',
                                state: test.endpoint
                            })}
                            >view result</div>
                            :
                            <div 
                            onClick={()=>history.push(`/test/${test.endpoint}`)}
                            className={styles.testButton}>
                                Give Test
                            </div>
                        }
                    </div>
                )
            })
        }
    </div>
    </>
  )
}

export default Main