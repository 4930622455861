import React from 'react'
import styles from '../TestResult.module.css'

function Test1({result}) {
  return (
    <>
                {
                    result &&
                    <>
                    {
                        result["Careers to Avoid"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Careers to Avoid</div>
                            <div>
                                {
                                    result["Careers to Avoid"].map(item => {
                                        return (
                                            <div className={styles.content}>{item}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }

                    {
                        result["Communication Style"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Communication Style</div>
                            <div className={styles.content}>{result["Communication Style"]}</div>
                        </div>
                    }

                    {
                        result["Growth Potential"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Growth Potential</div>
                            <div>
                                {
                                    result["Growth Potential"]["Assume it’s all constructive criticism. "] &&
                                    <div className={styles.section}>
                                        <div className={styles.subTitle}>Assume it’s all constructive criticism:</div> 
                                        <div className={styles.content}>{result["Growth Potential"]["Assume it’s all constructive criticism. "]}</div>
                                    </div>
                                }
                                {
                                    result["Growth Potential"]['Don’t ignore the long-term consequences'] &&
                                    <div className={styles.section}>
                                        <div className={styles.subTitle}>Don’t ignore the long-term consequences:</div> 
                                        <div className={styles.content}>{result["Growth Potential"]['Don’t ignore the long-term consequences']}</div>
                                    </div>
                                }
                                {
                                    result["Growth Potential"]["Investigate alternative learning strategies"] &&
                                    <div className={styles.section}>
                                        <div className={styles.subTitle}>Investigate alternative learning strategies:</div> 
                                        <div className={styles.content}>{result["Growth Potential"]["Investigate alternative learning strategies"]}</div>
                                    </div>
                                }
                                {
                                    result["Growth Potential"]["Make lists and write down goals"] &&
                                    <div className={styles.section}>
                                        <div className={styles.subTitle}>Make lists and write down goals:</div> 
                                        <div className={styles.content}>{result["Growth Potential"]["Make lists and write down goals"]}</div>
                                    </div>
                                }
                                {
                                    result["Growth Potential"]["Shine a flashlight into the darkest corners"] &&
                                    <div className={styles.section}>
                                        <div className={styles.subTitle}>Shine a flashlight into the darkest corners:</div>
                                        <div className={styles.content}>{result["Growth Potential"]["Shine a flashlight into the darkest corners"]}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    }


                    {
                        result["How Others Perceive You"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>How Others Perceive You</div>
                            <div className={styles.content}>{result["How Others Perceive You"]}</div>
                        </div>
                    }

                    {
                        result["Personality Description"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Personality Description</div>
                            {
                                result["Personality Description"].Description &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>Description</div>
                                    <div>{result["Personality Description"].Description}</div>
                                </div>
                            }   

                            {
                                result["Personality Description"]["How do you grasp and process the information?"] &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>How do you grasp and process the information?</div>
                                    <div className={styles.content}>
                                        {
                                            result["Personality Description"]["How do you grasp and process the information?"].map(item => {
                                                return (
                                                    <div className={styles.content}>{item}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }

                            {
                                result["Personality Description"]["How do you make the decisions?"] &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>How do you make the decisions?</div>
                                    <div className={styles.content}>
                                        {
                                            result["Personality Description"]["How do you make the decisions?"].map(item => {
                                                return (
                                                    <div className={styles.content}>{item}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }

                            {
                                result["Personality Description"]["How do you prefer to plan the work ?"] &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>How do you prefer to plan the work?</div>
                                    <div className={styles.content}>
                                        {
                                            result["Personality Description"]["How do you prefer to plan the work ?"].map(item => {
                                                return (
                                                    <div className={styles.content}>{item}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }

                            {
                                result["Personality Description"].Name &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>Name</div>
                                    <div>{result["Personality Description"].Name}</div>
                                </div>
                            }

                            {
                                result["Personality Description"]["Two Line Description"] &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>Two Line Description</div>
                                    <div className={styles.content}>{result["Personality Description"]["Two Line Description"]}</div>
                                </div>
                            }

                            {
                                result["Personality Description"]["Where do you prefer to focus your energy and attention?"] &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>Where do you prefer to focus your energy and attention?</div>
                                    <div className={styles.content}>
                                        {
                                            result["Personality Description"]["Where do you prefer to focus your energy and attention?"].map(item => {
                                                return (
                                                    <div className={styles.content}>{item}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {
                        result["Strengths and Weaknesses"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Strengths and Weaknesses</div>
                            {
                                result["Strengths and Weaknesses"].Strengths &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>Strengths</div>
                                    <ol>
                                        <li>
                                        {
                                            result["Strengths and Weaknesses"].Strengths["Bold and practical"] &&
                                            <div>
                                                <div className={styles.subTitle}>Bold and practical: </div>
                                                <div className={styles.content}>{result["Strengths and Weaknesses"].Strengths["Bold and practical"]}</div>
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Strengths and Weaknesses"].Strengths.Positivity &&
                                            <div className={styles.section}>
                                                <div className={styles.subTitle}>Positivity: </div>
                                                <div className={styles.content}>{result["Strengths and Weaknesses"].Strengths.Positivity}</div>
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Strengths and Weaknesses"].Strengths.Showmanship &&
                                            <div className={styles.section}>
                                                <div className={styles.subTitle}>Showmanship: </div>
                                                <div className={styles.content}>{result["Strengths and Weaknesses"].Strengths.Showmanship}</div>
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Strengths and Weaknesses"].Strengths.Supportive &&
                                            <div className={styles.section}>
                                                <div className={styles.subTitle}>Supportive: </div>
                                                <div className={styles.content}>{result["Strengths and Weaknesses"].Strengths.Supportive}</div>
                                            </div>
                                        }
                                        </li>
                                    </ol>
                                </div>
                            }

                            {
                                result["Strengths and Weaknesses"].Weaknesses &&
                                <div className={styles.section}>
                                    <div className={styles.subTitle}>Weaknesses</div>
                                    <ol>
                                        <li>
                                        {
                                            result["Strengths and Weaknesses"].Weaknesses["Avoid conflict"] &&
                                            <div>
                                                <div className={styles.subTitle}>Avoid conflict: </div>
                                                <div className={styles.content}>{result["Strengths and Weaknesses"].Weaknesses["Avoid conflict"]}</div>
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Strengths and Weaknesses"].Weaknesses["Easily bored"] &&
                                            <div className={styles.section}>
                                                <div className={styles.subTitle}>Easily bored: </div>
                                                <div className={styles.content}>{result["Strengths and Weaknesses"].Weaknesses["Easily bored"]}</div>
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Strengths and Weaknesses"].Weaknesses["Poor long-term focus"] &&
                                            <div className={styles.section}>
                                                <div className={styles.subTitle}>Poor long-term focus: </div>
                                                <div className={styles.content}>{result["Strengths and Weaknesses"].Weaknesses["Poor long-term focus"]}</div>
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Strengths and Weaknesses"].Weaknesses.Sensitive &&
                                            <div className={styles.section}>
                                                <div className={styles.subTitle}>Sensitive: </div>
                                                <div className={styles.content}>{result["Strengths and Weaknesses"].Weaknesses.Sensitive}</div>
                                            </div>
                                        }
                                        </li>
                                    </ol>
                                </div>
                            }
                        </div>
                    }

                    {
                        result["Values and Motivations"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Values and Motivations</div>
                            <div>{result["Values and Motivations"]}</div>
                        </div>
                    }

                    {
                        result["Work Place Habits"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Work Place Habits</div>
                            <div>{result["Work Place Habits"]}</div>
                        </div>
                    }
                    </>
                }
                </>
  )
}

export default Test1