import React,{useEffect,useState, useRef} from 'react'
import {useParams,useHistory} from 'react-router-dom'
import axios from 'axios'
import styles from './Test.module.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {IoIosArrowRoundForward} from 'react-icons/io'
import { Slider as SlideInput } from 'antd';
import 'antd/dist/antd.css';
import {BsCheckCircle} from 'react-icons/bs'
import { db } from '../../firebaseTools'
import firebase from 'firebase/compat';
import PulseLoader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";


function Test() {
    const {endpoint} = useParams()
    const sliderRef = useRef()
    const history = useHistory()

    const [testQuestion,setTestQuestion] = useState(null)
    const [loading,setLoading] = useState(true)
    const [ans,setAns] = useState(null)
    const [ansArray,setAnsArray] = useState([])
    const [showSubmit,setShowSubmit] = useState(false)
    const [submitted,setSubmitted] = useState(false)
    const [submitLoader,setSubmitLoader] = useState(false)

    useEffect(()=>{
        if(endpoint){
            getTest()
        }
    },[endpoint])

    const getTest = () => {
        const url = `https://totalappi.herokuapp.com/${endpoint}/questions`
        axios.get(url)
        .then(res => { 
            if(endpoint==='test1' || endpoint==='test2' || endpoint==='test3'){
                setTestQuestion(res.data)
            }
            if(endpoint==='test4'){
                setTestQuestion(res.data.Questions)
            }
            if(endpoint==='test5'){
                setTestQuestion(res.data.questions)
            }
            if(endpoint==='test6'){
                setTestQuestion(res.data)
            }
            if(endpoint==='test7'){
                setTestQuestion(res.data)
            }
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
        })
    }


    let length = 0;
    {
        if(testQuestion){
            length = testQuestion.length
        }
    }

    //react-slick
    const settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        afterChange: (currentSlide) => {
            if(currentSlide+1===length){
                setShowSubmit(true)
            }
        }
    };

    console.log(ansArray)

    const nextQuestion = () => {
        console.log(ans)
        if(ans===null){
            alert('INVALID OPTION')
            return
        }
        sliderRef.current.slickNext()
        setAnsArray([...ansArray, ans])
        setAns(null)
    }
    

    const marks = {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5'
      };

      const test3marks = {
        0: '0',
        1: '1'
      };

    const test4marks = {
        "1": "ABSOLUTELY DISAGREE",
        "2": "DISAGREE",
        "3": "UNSURE",
        "4": "AGREE",
        "5": "ABSOLUTELY AGREE"
    }

    const test5marks = {
        "0": "Does NOT apply",
        "1": "Applies about HALF the time",
        "2": "ALWAYS applies"
    }

    const changeAnswer = (value) => {
        setAns(value)
    }

    const submitTest = () => {
        setAnsArray([...ansArray, ans])
        alert('SUBMIT TEST?')

        setSubmitLoader(true)
        if(endpoint==='test6'){
            setAnsArray(ansArray.map(item => parseInt(item)))
        }
        const param = ansArray.join(" ")
        let url = `https://totalappi.herokuapp.com/${endpoint}/answers/${param}`
        if(endpoint==='test6'){
            url = `https://totalappi.herokuapp.com/${endpoint}/responses/${param}`
        }
        axios.get(url)
        .then(res => {
            console.log(res.data)
            // setSubmitted(true)

            const user = JSON.parse(sessionStorage.getItem('user'))
            console.log(user)
            db.collection("contacts").doc(`${user.Contact}`).update({
                result: firebase.firestore.FieldValue.arrayUnion({[endpoint]:res.data})
            })
            .then(()=>{
                console.log('result added to document')
                setSubmitLoader(false)
                setSubmitted(true)
            })
            .catch(err=>{
                console.log(err)
            })
        })
        .catch(err => {
            console.log(err)
        })
    }

    const closeModal = () => {
        history.push('/tests')
    }

    const handleCheck = (value) => {
        console.log(value)
        setAns(value)
    }

    const test1Options = [
        'Strongly Disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree'
    ]

    const test5Options = [
        'Does not apply to me',
        'Applies half the time',
        'Always applies to me'
    ]

  return (
    <div>
        {
            submitted &&
            <div className={styles.modalContainer}>
                <div className={styles.modal}>
                    <BsCheckCircle className={styles.modalIcon}/>
                    <div className={styles.modalMessage}>Your test has been submitted successfully</div>
                    <button className={styles.submitButton} onClick={closeModal}>OK</button>
                </div>
            </div>
        }

        {
            submitLoader &&
            <div className={styles.modalContainer}>
                <div style={{
                    width:'fit-content',
                    position:'absolute',
                    top:'50%',
                    transform:'translateY(-50%)',
                    left:'0',
                    right:'0',
                    margin:'auto'
                }}>
                    <ClipLoader color='white' loading={true} size={60} />
                </div>
            </div>
        }

        {
            loading && 
            <div style={{
                width:'fit-content',
                position:'absolute',
                top:'50%',
                transform:'translateY(-50%)',
                left:'0',
                right:'0',
                margin:'auto',
                fontFamily: 'Poppins',
                display:'flex',
                alignItems:'center'
            }}>
                <div style={{
                    marginRight:'0.5vw',
                    fontSize:'2.8vh',
                    fontWeight:'bold',
                    fontFamily:'Poppins',
                    color:'#2f3679'
                }}>
                    Loading test
                </div>
                <PulseLoader color='#2f3679' loading={true} size={8} />
        </div>
        }

        {
            testQuestion &&
            <div className={styles.testContainer}>
            <Slider ref={sliderRef} {...settings} className={styles.carousel}>
                {
                    testQuestion &&
                    testQuestion.map((item,index) => {
                        if(endpoint==='test6'){
                            return (
                                <div key={index}>
                                    <div className={styles.questionNo}>question {index+1} of {testQuestion.length}</div>
                                    <div style={{fontFamily:'Poppins',fontSize:'2.8vh'}}>{item.Questions}</div>
                                    <div style={{margin:'4vh 0'}}>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <input type="checkbox" name='check' value='1' 
                                            checked={ans==='1'? true : false}
                                            onChange={(e)=>handleCheck(e.target.value)}
                                            className={styles.checkbox} />
                                            <div className={styles.option}>{item['Option A']}</div>
                                        </div>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <input type="checkbox" name='check' 
                                            value='2'
                                            checked={ans==='2'? true : false}
                                             onChange={(e)=>handleCheck(e.target.value)}
                                             className={styles.checkbox} />
                                            <div className={styles.option}>{item['Option B']}</div>
                                        </div>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <input type="checkbox" name='check'
                                             value='3'
                                             checked={ans==='3'? true : false} 
                                             onChange={(e)=>handleCheck(e.target.value)}
                                             className={styles.checkbox} />
                                            <div className={styles.option}>{item['Option C']}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div key={index}>
                                    <div className={styles.questionNo}>question {index+1} of {testQuestion.length}</div>
                                    <div className={styles.question}>{item}</div>
    
                                    {
                                        endpoint==='test1' &&
                                        // <SlideInput min={1} max={5} marks={marks} defaultValue={1} onChange={changeAnswer} style={{width:'60%',margin:'10vh auto'}} />
                                        <div style={{margin:'4vh 0'}}>
                                            {
                                                test1Options.map((item,index) => {
                                                    return (
                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                            <input type="checkbox" name='check' value={index+1}
                                                            checked={ans===`${index+1}`? true : false}
                                                            onChange={(e)=>handleCheck(e.target.value)}
                                                            className={styles.checkbox} />
                                                            <div className={styles.option}>{item}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
    
                                    {
                                        endpoint==='test2' &&
                                        // <SlideInput min={1} max={5} marks={marks} defaultValue={1} onChange={changeAnswer} style={{width:'60%',margin:'10vh auto'}} />
                                        <div style={{margin:'4vh 0'}}>
                                            {
                                                test1Options.map((item,index) => {
                                                    return (
                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                            <input type="checkbox" name='check' value={index+1}
                                                            checked={ans===`${index+1}`? true : false}
                                                            onChange={(e)=>handleCheck(e.target.value)}
                                                            className={styles.checkbox} />
                                                            <div className={styles.option}>{item}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
    
                                    {
                                        endpoint==='test3' &&
                                        // <SlideInput min={0} max={1} marks={test3marks} defaultValue={1} onChange={changeAnswer} style={{width:'20%',margin:'10vh auto'}} />
                                        <div style={{margin:'4vh 0'}}>
                                            <div style={{display:'flex',alignItems:'center'}}>
                                                <input type="checkbox" name='check' value='0' 
                                                checked={ans==='0'? true : false}
                                                onChange={(e)=>handleCheck(e.target.value)}
                                                className={styles.checkbox} />
                                                <div className={styles.option}>Yes</div>
                                            </div>
                                            <div style={{display:'flex',alignItems:'center'}}>
                                                <input type="checkbox" name='check' 
                                                value='1'
                                                checked={ans==='1'? true : false}
                                                onChange={(e)=>handleCheck(e.target.value)}
                                                className={styles.checkbox} />
                                                <div className={styles.option}>No</div>
                                            </div>
                                        </div>
                                    }
    
                                    {
                                        endpoint==='test4' &&
                                        // <SlideInput min={1} max={5} marks={test4marks} defaultValue={1} onChange={changeAnswer} style={{width:'80%',margin:'10vh auto'}} />
                                        <div style={{margin:'4vh 0'}}>
                                            {
                                                test1Options.map((item,index) => {
                                                    return (
                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                            <input type="checkbox" name='check' value={index+1}
                                                            checked={ans===`${index+1}`? true : false}
                                                            onChange={(e)=>handleCheck(e.target.value)}
                                                            className={styles.checkbox} />
                                                            <div className={styles.option}>{item}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
    
                                    {
                                        endpoint==='test5' &&
                                        // <SlideInput min={0} max={2} marks={test5marks} defaultValue={1} onChange={changeAnswer} style={{width:'60%',margin:'10vh auto'}} />
                                        <div style={{margin:'4vh 0'}}>
                                            {
                                                test5Options.map((item,index) => {
                                                    return (
                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                            <input type="checkbox" name='check' value={index}
                                                            checked={ans===`${index}`? true : false}
                                                            onChange={(e)=>handleCheck(e.target.value)}
                                                            className={styles.checkbox} />
                                                            <div className={styles.option}>{item}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        }
                    })
                }
            </Slider>
            <div className={styles.nextButtonContainer}>
                {
                    showSubmit ?
                    <button className={styles.submitButton} onClick={submitTest}>Submit</button>
                    :
                    <div style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'flex-end'
                    }}>
                        <div style={{fontFamily:'Poppins'}}>Next</div>
                        <IoIosArrowRoundForward className={styles.next} onClick={nextQuestion}/>
                    </div>
                }
            </div>
            </div>
        }
    </div>
  )
}

export default Test