import React,{useEffect,useState} from 'react'
import {useLocation} from 'react-router-dom'
import { db } from "../../firebaseTools.js";
import styles from './TestResult.module.css'
import ClipLoader from "react-spinners/ClipLoader";
import Test6 from './Results/Test6.js';
import Test5 from './Results/Test5.js';
import Test3 from './Results/Test3.js';
import Test2 from './Results/Test2.js';
import Test4 from './Results/Test4.js';
import Test1 from './Results/Test1.js';

function TestResult() {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const [registerLoader,setRegisterLoader] = useState(false)

    const location = useLocation()

    const [result,setResult] = useState(null)

    useEffect(()=>{
        setRegisterLoader(true)
        let userResult = db.collection('contacts').doc(`${user.Contact}`)
        userResult.get().then(function(doc) {
            if (doc.exists) {
                let data = doc.data().result
                console.log(data)
                data.map((item,index) => {
                    if(Object.keys(doc.data().result[index]).toString()===location.state){
                        setResult(doc.data().result[index][location.state])
                    }
                })
                setRegisterLoader(false)
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    },[])



    console.log({result})

  return (
    <>
    {
        registerLoader &&
        <div className={styles.modalContainer}>
           <div style={{
                    width:'fit-content',
                    position:'absolute',
                    top:'50%',
                    transform:'translateY(-50%)',
                    left:'0',
                    right:'0',
                    margin:'auto'
                }}>
                    <ClipLoader color='white' loading={true} size={60} />
            </div>
        </div> 
      }

      <div className={styles.resultContainer}>
        <div className={styles.result}>RESULT</div>
        <div style={{padding:'5vh 2vw',backgroundColor:'#F8F8F8'}}>
            {
                location.state==='test1' && 
                <Test1 result={result}/>
            }

            {
                location.state==='test2' &&
                <Test2 result={result}/>
            }

            {
                location.state==='test3' &&
                <Test3 result={result}/>
            }

{
                location.state==='test4' &&
                <Test4 result={result}/>
            }
        {
            location.state==='test5' &&
            <Test5 result={result} />
        }
        {
            location.state==='test6' &&
            <Test6 result={result} />
        }
        </div>
      </div>
    </>
  )
}

export default TestResult