import React,{useState,useEffect} from 'react'
import styles from '../TestResult.module.css'

function Test2({result}) {
    const [primarykey,setPrimaryKey] = useState(null)
    const [secondarykey,setSecondaryKey] = useState(null)
    const [tertiarykey,setTertiaryKey] = useState(null)

    useEffect(()=>{
        if(result && result["Primary Interest"]){
            setPrimaryKey(Object.keys(result["Primary Interest"]).toString())
        }

        if(result && result["Secondary Interest"]){
            setSecondaryKey(Object.keys(result["Secondary Interest"]).toString())
        }

        if(result && result["Tertiary Interest"]){
            setTertiaryKey(Object.keys(result["Tertiary Interest"]).toString())
        }
    },[result])

    console.log({primarykey,secondarykey,tertiarykey})

  return (
    <>
                {
                    result &&
                    <>
                     {
                        result.Attribute &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Attribute</div>
                            <div className={styles.content}>{result.Attribute}</div>
                        </div>
                    }

                    {
                        result["Primary Interest"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Primary Interest</div>
                            {
                                result["Primary Interest"][`${primarykey}`] &&
                                <div>
                                    <div className={styles.subTitle}>{primarykey}</div>
                                    <ol>
                                        <li>
                                        {
                                            result["Primary Interest"][`${primarykey}`]["Core Values"] &&
                                            <div>
                                                <div className={styles.subTitle}>Core Values</div>
                                                {
                                                    result["Primary Interest"][`${primarykey}`]["Core Values"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Primary Interest"][`${primarykey}`]["Description"] &&
                                            <div>
                                                <div className={styles.subTitle}>Description</div>
                                                {
                                                    result["Primary Interest"][`${primarykey}`]["Description"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                            {
                                                result["Primary Interest"][`${primarykey}`].Name &&
                                                <div className={styles.section}>
                                                    <div className={styles.subTitle}>Name</div>
                                                    <div className={styles.content}>{result["Primary Interest"][`${primarykey}`].Name}</div>
                                                </div>
                                            }
                                        </li>

                                        <li>
                                            {
                                                result["Primary Interest"][`${primarykey}`].Quotes &&
                                                <div className={styles.section}>
                                                    <div className={styles.subTitle}>Quotes</div>
                                                    <div className={styles.content}>{result["Primary Interest"][`${primarykey}`].Quotes}</div>
                                                </div>
                                            }
                                        </li>

                                        <li>
                                        {
                                            result["Primary Interest"][`${primarykey}`]["Dominant Traits"] &&
                                            <div>
                                                <div className={styles.subTitle}>Dominant Traits</div>
                                                {
                                                    result["Primary Interest"][`${primarykey}`]["Dominant Traits"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Primary Interest"][`${primarykey}`]["Top Job Tasks"] &&
                                            <div>
                                                <div className={styles.subTitle}>Top Job Tasks</div>
                                                {
                                                    result["Primary Interest"][`${primarykey}`]["Top Job Tasks"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Primary Interest"][`${primarykey}`]["You Like"] &&
                                            <div>
                                                <div className={styles.subTitle}>You Like</div>
                                                {
                                                    result["Primary Interest"][`${primarykey}`]["You Like"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>
                                    </ol>
                                </div>
                            }
                        </div>
                    }

                    {
                        result["Secondary Interest"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Secondary Interest</div>
                            {
                                result["Secondary Interest"][`${secondarykey}`] &&
                                <div>
                                    <div className={styles.subTitle}>{secondarykey}</div>
                                    <ol>
                                        <li>
                                        {
                                            result["Secondary Interest"][`${secondarykey}`]["Core Values"] &&
                                            <div>
                                                <div className={styles.subTitle}>Core Values</div>
                                                {
                                                    result["Secondary Interest"][`${secondarykey}`]["Core Values"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Secondary Interest"][`${secondarykey}`]["Description"] &&
                                            <div>
                                                <div className={styles.subTitle}>Description</div>
                                                {
                                                    result["Secondary Interest"][`${secondarykey}`]["Description"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                            {
                                                result["Secondary Interest"][`${secondarykey}`].Name &&
                                                <div className={styles.section}>
                                                    <div className={styles.subTitle}>Name</div>
                                                    <div className={styles.content}>{result["Secondary Interest"][`${secondarykey}`].Name}</div>
                                                </div>
                                            }
                                        </li>

                                        <li>
                                            {
                                                result["Secondary Interest"][`${secondarykey}`].Quotes &&
                                                <div className={styles.section}>
                                                    <div className={styles.subTitle}>Quotes</div>
                                                    <div className={styles.content}>{result["Secondary Interest"][`${secondarykey}`].Quotes}</div>
                                                </div>
                                            }
                                        </li>

                                        <li>
                                        {
                                            result["Secondary Interest"][`${secondarykey}`]["Dominant Traits"] &&
                                            <div>
                                                <div className={styles.subTitle}>Dominant Traits</div>
                                                {
                                                    result["Secondary Interest"][`${secondarykey}`]["Dominant Traits"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Secondary Interest"][`${secondarykey}`]["Top Job Tasks"] &&
                                            <div>
                                                <div className={styles.subTitle}>Top Job Tasks</div>
                                                {
                                                    result["Secondary Interest"][`${secondarykey}`]["Top Job Tasks"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Secondary Interest"][`${secondarykey}`]["You Like"] &&
                                            <div>
                                                <div className={styles.subTitle}>You Like</div>
                                                {
                                                    result["Secondary Interest"][`${secondarykey}`]["You Like"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>
                                    </ol>
                                </div>
                            }
                        </div>
                    }

{
                        result["Tertiary Interest"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Tertiary Interest</div>
                            {
                                result["Tertiary Interest"][`${tertiarykey}`] &&
                                <div>
                                    <div className={styles.subTitle}>{tertiarykey}</div>
                                    <ol>
                                        <li>
                                        {
                                            result["Tertiary Interest"][`${tertiarykey}`]["Core Values"] &&
                                            <div>
                                                <div className={styles.subTitle}>Core Values</div>
                                                {
                                                    result["Tertiary Interest"][`${tertiarykey}`]["Core Values"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Tertiary Interest"][`${tertiarykey}`]["Description"] &&
                                            <div>
                                                <div className={styles.subTitle}>Description</div>
                                                {
                                                    result["Tertiary Interest"][`${tertiarykey}`]["Description"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                            {
                                                result["Tertiary Interest"][`${tertiarykey}`].Name &&
                                                <div className={styles.section}>
                                                    <div className={styles.subTitle}>Name</div>
                                                    <div className={styles.content}>{result["Tertiary Interest"][`${tertiarykey}`].Name}</div>
                                                </div>
                                            }
                                        </li>

                                        <li>
                                            {
                                                result["Tertiary Interest"][`${tertiarykey}`].Quotes &&
                                                <div className={styles.section}>
                                                    <div className={styles.subTitle}>Quotes</div>
                                                    <div className={styles.content}>{result["Tertiary Interest"][`${tertiarykey}`].Quotes}</div>
                                                </div>
                                            }
                                        </li>

                                        <li>
                                        {
                                            result["Tertiary Interest"][`${tertiarykey}`]["Dominant Traits"] &&
                                            <div>
                                                <div className={styles.subTitle}>Dominant Traits</div>
                                                {
                                                    result["Tertiary Interest"][`${tertiarykey}`]["Dominant Traits"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Tertiary Interest"][`${tertiarykey}`]["Top Job Tasks"] &&
                                            <div>
                                                <div className={styles.subTitle}>Top Job Tasks</div>
                                                {
                                                    result["Tertiary Interest"][`${tertiarykey}`]["Top Job Tasks"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>

                                        <li>
                                        {
                                            result["Tertiary Interest"][`${tertiarykey}`]["You Like"] &&
                                            <div>
                                                <div className={styles.subTitle}>You Like</div>
                                                {
                                                    result["Tertiary Interest"][`${tertiarykey}`]["You Like"].map(item => {
                                                        return (
                                                            <div className={styles.content}>{item}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        </li>
                                    </ol>
                                </div>
                            }
                        </div>
                    }

                    {
                        result.score &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Score</div>
                            <div>
                                {
                                    result.score.Artistic &&
                                    <div className={styles.content}>
                                        Artistic: {result.score.Artistic}
                                    </div>
                                }
                                {
                                    result.score.Conventional &&
                                    <div className={styles.content}>
                                        Conventional: {result.score.Conventional}
                                    </div>
                                }
                                {
                                    result.score.Enterprising &&
                                    <div className={styles.content}>
                                        Enterprising: {result.score.Enterprising}
                                    </div>
                                }
                                {
                                    result.score.Investigative &&
                                    <div className={styles.content}>
                                        Investigative: {result.score.Investigative}
                                    </div>
                                }
                                {
                                    result.score.Realistic &&
                                    <div className={styles.content}>
                                        Realistic: {result.score.Realistic}
                                    </div>
                                }
                                {
                                    result.score.Social &&
                                    <div className={styles.content}>
                                        Social: {result.score.Social}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    </>
                }
                </>
  )
}

export default Test2