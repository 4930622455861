import React from 'react'
import './Application.css'
import CirclePhone from '../../assets/images/circle-phone.png'
import ApplePlay from '../../assets/images/contact_apple.svg'
import GooglePlay from '../../assets/images/contact_google.svg'

import Phone1 from '../../assets/images/Phone1.png'
import Phone2 from '../../assets/images/Phone2.png'
import Phone3 from '../../assets/images/Phone3.png'
import Phone4 from '../../assets/images/Phone4.png'
import Phone5 from '../../assets/images/Phone5.png'
import Phone6 from '../../assets/images/Phone6.png'
import Phone8 from '../../assets/images/Phone8.png'
import Phone9 from '../../assets/images/Phone9.png'
import Phone10 from '../../assets/images/Phone10.png'
import Phone11 from '../../assets/images/Phone11.png'
import SetYourTargetBack from '../../assets/images/SetYourTargetBack.png'
import LeftBackground from '../../assets/images/Application-left-background.png'
import BottomBackground from '../../assets/images/Application-bottom-background.png'

function Application() {
    return (
        <>
        <div className='application'>
            <div className='application-title'>
                <div className='application-title-1'>INFIGON</div>
                <div className='application-title-2'>The Career Mentor</div>
            </div>

            <div className='circle-container'>
                <div className='application-circle'></div>
                <div className='application-circle-heading'>
                    ONE STOP DESTINATION FOR CAREER <br/>
                    PLANNING

                    <div className='download-now'>
                        <div>Download Now</div>
                        <div>
                            <span>
                                <a
                                    href="https://apps.apple.com/in/app/infigon-the-career-mentor/id1564255604"
                                    target="_blank"
                                >
                                    <img src={ApplePlay}></img>
                                </a>
                            </span>
                            <span>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.tech.infigon"
                                    target="_blank"
                                >
                                    <img src={GooglePlay}></img>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                <img src={CirclePhone} className='circle-phone'/>
            </div>
        </div>

        <div className='test-container'>

            <img src={LeftBackground} className='test-container-background'/>

            <div className='test-container-title'>
                TAKE TESTS <br/>
                EXPLORE OPTIONS
            </div>

            <img src={Phone1} className='phone1'/>
            <img src={Phone2} className='phone2'/>
            <img src={Phone3} className='phone3'/>
            <img src={Phone4} className='phone4'/>
            <img src={Phone5} className='phone5'/>
            <img src={Phone6} className='phone6'/>
            
        </div>

        <div className='set-your-targets'>
            <div className='set-your-targets-container1'>
                SET YOUR TARGETS
            </div>
            <div className='set-your-targets-container2'>
                <img src={SetYourTargetBack} className='set-your-targets-background'/>
                <img src={Phone8} className='phone8'/>
            </div>
        </div>

        <div className='build-your-resume'>
            <img src={Phone9} className='phone9'/>
            <div className='build-your-resume-title'>BUILD YOUR RESUME</div>
            <img src={Phone10} className='phone9'/>
        </div>


        <div className='application-blogs'>
            <div className='application-blogs-main'>
                <div className='application-blogs-title'>
                    <div>BLOGS</div>
                    <div className='application-blogs-subtitle'>GET THE LATEST PERSONALIZED UPDATES ABOUT YOUR TARGETCAREER OPTIONS</div>
                </div>
                <img src={Phone11} className='application-blogs-image'/>
            </div>
            <img src={BottomBackground} className='application-bottom-background'/>
        </div>

        </>
    )
}

export default Application
