// import React from "react";
import "./Mentors.css";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebaseTools";
import "react-slideshow-image/dist/styles.css";
import Carousel1 from "react-elastic-carousel";
import mentor_logo from "../../../assets/images/Vector 6.png";
import { Carousel } from "react-responsive-carousel";

function Mentors() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 600, itemsToShow: 2 },
    { width: 800, itemsToShow: 3 },
    { width: 900, itemsToShow: 4 },
    { width: 1000, itemsToShow: 5 }, 
  ];

  const [anicard, setanicard] = useState(false);

  const breakPoints1 = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 900, itemsToShow: 2 },
    { width: 1300, itemsToShow: 3 },
    { width: 1700, itemsToShow: 4 },
    { width: 2200, itemsToShow: 5 },
  ];

  const xwidth = window.screen.width;

  const background_card = [
    { background: "linear-gradient(198.99deg, #FB8955 9.61%, #F9A93B 96.04%)" },
    { background: "linear-gradient(198.99deg, #A1DD97 9.61%, #BCDEC9 96.04%)" },
    { background: "linear-gradient(198.99deg, #B6A4DD 9.61%, #E0A1CC 96.04%)" },
    { background: "linear-gradient(198.99deg, #4BB79E 9.61%, #50D659 96.04%)" },
    {
      background: " linear-gradient(198.99deg, #D34782 9.61%, #E4A7C5 96.04%)",
    },
  ];
  const [ourmentor, setourmentor] = useState([]);
  const [ourmentor1, setourmentor1] = useState([]);
  useEffect(() => {
    db.collection("ourmentor").onSnapshot((snapshot) => {
      setourmentor(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });

    db.collection("ourmentors").onSnapshot((snapshot) => {
      setourmentor1(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, []);

  return (
    <div>
      <div id="bodymiddle">
        <div id="mentorstext">Meet Our Mentors</div>
      </div>
      <br />
      <br />
      <div id="Bodycarouselimg">
        <Carousel
          infiniteLoop={false}
          initialSlide={true}
          pauseOnHover={true}
          interval={5000}
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          autoPlay={true}
          showIndicators={false}
          autoFocus={true}
        >
          {ourmentor.map((each, index) => (
            <div className="mentor_maincard">
              <div
                className="mentor_cards"
                style={{
                  background: each.data.background,
                  // marginRight: "9%",
                }}
                key={index}
                onMouseEnter={() => setanicard(true)}
                onMouseLeave={() => setanicard(false)}
              >
                <img
                  src={each.data.image}
                  className={anicard ? "mentor_img2" : "mentor_img"}
                  alt="..."
                  style={{
                    height: `220px`,
                    width: "220px",
                    borderRadius: "15px",
                  }}
                />
                <div className="mentor_body">
                  <p className="mentor-text" style={{ textAlign: "center" }}>
                    {
                      <h5
                        className="mentor-title"
                        style={{ color: "#FFFFFF", textAlign: "center" }}
                      >
                        {each.data.name}
                      </h5>
                    }
                    <h6
                      className="mentor-title"
                      style={{ color: "#FFFFFF", textAlign: "center" }}
                    >
                      {each.data.degree}
                    </h6>
                    <h6
                      className="mentor-title"
                      style={{ color: "#FFFFFF", textAlign: "center" }}
                    >
                      {each.data.college}
                    </h6>
                    <span className="mentor-designation">
                      {each.data.description}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="mentor_cards"
                style={{
                  background: each.data.background1,
                  // marginRight: "9%",
                }}
                key={index}
                onMouseEnter={() => setanicard(true)}
                onMouseLeave={() => setanicard(false)}
              >
                <img
                  src={each.data.image1}
                  className={anicard ? "mentor_img2" : "mentor_img"}
                  alt="..."
                  style={{
                    height: `220px`,
                    width: "220px",
                    borderRadius: "15px",
                  }}
                />
                <div className="mentor_body">
                  <p className="mentor-text" style={{ textAlign: "center" }}>
                    {
                      <h5
                        className="mentor-title"
                        style={{ color: "#FFFFFF", textAlign: "center" }}
                      >
                        {each.data.name1}
                      </h5>
                    }
                    <h6
                      className="mentor-title"
                      style={{ color: "#FFFFFF", textAlign: "center" }}
                    >
                      {each.data.degree1}
                    </h6>
                    <h6
                      className="mentor-title"
                      style={{ color: "#FFFFFF", textAlign: "center" }}
                    >
                      {each.data.college1}
                    </h6>
                    <span className="mentor-designation">
                      {each.data.description1}
                    </span>
                  </p>
                </div>
              </div>
              {each.data.description2 === "false" ? (
                ""
              ) : (
                <div
                  className="mentor_cards"
                  style={{
                    background: each.data.background2,
                    // marginRight: "9%",
                  }}
                  key={index}
                  onMouseEnter={() => setanicard(true)}
                  onMouseLeave={() => setanicard(false)}
                >
                  <img
                    src={each.data.image2}
                    className={anicard ? "mentor_img2" : "mentor_img"}
                    alt="..."
                    style={{
                      height: `220px`,
                      width: "220px",
                      borderRadius: "15px",
                    }}
                  />
                  <div className="mentor_body">
                    <p className="mentor-text" style={{ textAlign: "center" }}>
                      {
                        <h5
                          className="mentor-title"
                          style={{ color: "#FFFFFF", textAlign: "center" }}
                        >
                          {each.data.name2}
                        </h5>
                      }
                      <h6
                        className="mentor-title"
                        style={{ color: "#FFFFFF", textAlign: "center" }}
                      >
                        {each.data.degree2}
                      </h6>
                      <h6
                        className="mentor-title"
                        style={{ color: "#FFFFFF", textAlign: "center" }}
                      >
                        {each.data.college2}
                      </h6>
                      <span className="mentor-designation">
                        {each.data.description2}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Carousel>
        <br />
        <br />
      </div>
      <div id="Bodycarouselimg1">
        <Carousel1 breakPoints={breakPoints}>
          {ourmentor1.map((each, index) => (
            <div
              className="mentor_cards1"
              style={{
                background: background_card[index].background,
                marginRight: "0%",
                width: "269px",
              }}
              key={index}
            >
              <img
                src={each.data.image}
                className="mentor_img1"
                alt="..."
                style={{ height: "220px", width: "100%", objectFit: "cover" }}
              />
              <div className="mentor_body1">
                <p className="mentor-text1" style={{ textAlign: "center" }}>
                  {
                    <h5
                      className="mentor-title1"
                      style={{ color: "#FFFFFF", textAlign: "center" }}
                    >
                      {each.data.name}
                    </h5>
                  }
                  <h6
                    className="mentor-title"
                    style={{ color: "#FFFFFF", textAlign: "center" }}
                  >
                    {each.data.degree}
                  </h6>
                  <h6
                    className="mentor-title"
                    style={{ color: "#FFFFFF", textAlign: "center" }}
                  >
                    {each.data.college}
                  </h6>
                  <span className="mentor-designation1">
                    {each.data.designation}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </Carousel1>
        <br />
        <br />
      </div>
    </div>
  );
}

export default Mentors;
