import React, { useEffect, useRef } from "react";
import cloud from "../../../assets/images/2.png";
import cloud1 from "../../../assets/images/3.png";
import teaching from "../../../assets/images/Rocket High Quality.png";
import { db } from "../../../firebaseTools";
import { useState } from "react";
import Programs, { Popedprogram } from "../Programs/Program";
import "./body.css";
import Process from "../FivestepProcess/Process";
import Bodybottom from "../BodyCarousel/Mentors";
import Ourprogram from "../OurPrograms/Ourprogram";
import Testmonials from "../Testimonials/Testimonialsbody";
import FooterBody from "../FooterBody/Footerbody";
import mobilecloud from "../../../assets/images/mobilecloud.png";

import { useDispatch, useSelector } from "react-redux";
import {
  CheckButton,
  selectCheck,
  selectToggle,
  ToggleTheForm,
} from "../../../feature/ToggleSlice";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";

function Body() {
  const [Info, setInfo] = useState({
    GetInTouchEmail: "",
  });

  const ref = useRef();
  const reset = () => {
    ref.current.value = "";
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const checkbutton = useSelector(selectCheck);
  const dispatch = useDispatch();

  const togglecheck = useSelector(selectToggle);

  useEffect(() => {
    if (checkbutton === true) {
      dispatch(CheckButton(false));
    }
  }, []);

  let [toggle1, settoggle] = useState(0);

  function Popover() {
    settoggle(!toggle1);
    if (toggle1 == 1) {
      dispatch(ToggleTheForm(true));
      document.getElementById("popover").style.display = "block";
    }
    if (toggle1 == 0) {
      dispatch(ToggleTheForm(false));
      document.getElementById("popover").style.display = "none";
    }
  }

  const nullvalue = () => {
    let x = document.getElementById("nonempty").value;
    if (x == "") {
      alert("Please enter the details!!");
    } else {
      DirectToFirebase();
      alert("Our representative will contact you soon");
    }
  };

  const notification = () => {
    toast.success("You have successfully subscribed", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const Validate = (data) => {
    const ywidth = window.screen.width;
    if (ywidth >= 1080) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    db.collection("subscribeEmail").doc().set({
      email: data.email,
    });
    notification();
  };

  async function DirectToFirebase() {
    const res = await fetch(
      "https://infigondb-default-rtdb.firebaseio.com/InfigongetintouchBody.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          GetInTouchDBEmail: Info.GetInTouchEmail,
        }),
      }
    );
    db.collection("getintouch").add({
      GetInTouchDBEmail: Info.GetInTouchEmail,
    });
  }

  //Redering Return what is the UI
  return (
    <div>
      <div id="homepage">
        <div className="logoright">
          <img src={cloud} alt="logo" />
        </div>

        <div className="homemobilelogo">
          <img src={mobilecloud} alt="top" style={{width:"100%", height:"100%"}}/>
        </div>
        
        <div id="homepagebody">
          <div id="homepagetext">
            <div className="text1">
              <h1>Unlock your</h1>
              <h1>
                <span className="future">
                  <span style={{ color: "#728FCE" }}>FU</span>
                  <span style={{ color: "#E38AAE" }}>TU</span>
                  <span style={{ color: "#FFA07A" }}>RE</span>
                </span>{" "}
                with us
              </h1>
            </div>

            <div className="text2">
              A one stop destination for your{" "}
              <span style={{ color: "#2B60DE" }}> Career</span> Selection,
              <br></br>
              <span style={{ color: "#2B60DE" }}> Education</span> Planning and{" "}
              <span style={{ color: "#2B60DE" }}>Skill</span> development.
            </div>
          </div>

          <br></br>
          <div className="inputbox">
            <input
              id="nonempty"
              style={{
                width: "50%",
                height: "2.2rem",
                textAlign: "center",
                outline: "none",
                border: "1px solid rgba(0,0,0,0.2)",
                marginRight: "1%",
              }}
              placeholder="Enter your E-mail"
              type="email"
              pattern="[^ @]*@[^ @]*"
              onChange={(e) => {
                setInfo({ ...Info, GetInTouchEmail: e.target.value });
              }}
              required
              ref={ref}
            ></input>
            <span>
              <button
                onClick={(e) => {
                  nullvalue();
                  e.preventDefault();
                  reset();
                }}
                id="buttonAll"
                style={{ outline: "none", border: "none" }}
              >
                Book a Demo
              </button>
            </span>
          </div>
          <br></br>
          <br></br>
          <div id="countbox" className="center">
            <div id="counterbox">
              <span className="counternumber"></span>
              <span
                style={{
                  fontSize: "40px",
                  marginLeft: "3%",
                }}
              >
                K+
              </span>
              <div className="countername">Students reached </div>
            </div>
            <br></br>
            <div id="counterbox1">
              <span className="counternumber1"></span>
              <span
                style={{
                  fontSize: "40px",
                  marginLeft: "3%",
                }}
              >
                K+
              </span>
              <div className="countername">Career roadmaps prepared </div>
            </div>
            <br></br>
            <div id="counterbox">
              <span className="counternumber2"></span>
              <span
                style={{
                  fontSize: "40px",
                  marginLeft: "3%",
                }}
              >
                +
              </span>
              <div className="countername">Student mentors </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {!togglecheck && (
        <>
          <div className="logoleft">
            <img
              src={cloud1}
              alt="Cloud"
              style={{ width: "100%", height: "100%", marginTop: "45%" }}
            ></img>
          </div>
          <div id="right">
            <img class="imagepad" src={teaching} alt="teach" id="img"></img>
          </div>

          {/* <div>
            <Bodybottom />
          </div> */}
          <div>
            <Process />
          </div>
          <div>
            <Ourprogram />
          </div>
          {/* <div>
            <Testmonials />
          </div> */}
          {/* <div >
            <FooterBody />
          </div> */}
        </>
      )}
      <div>
        <Programs />
      </div>
    </div>
  );
}

export default Body;
