import React, { useEffect, useState } from "react";
import "./AboutUs.css";
import "./Responsive.css";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import mobilecloud from "../../../assets/images/mobilecloud.png";
import cloud from "../../../assets/images/2.png";
import upcloud from "../../../assets/AboutUs/37.png";
import upcloud2 from "../../../assets/AboutUs/36.png";
import midcloud from "../../../assets/AboutUs/5.png";
import lastCloud from "../../../assets/AboutUs/17.png";
import rotated1 from "../../../assets/AboutUs/32.png";
import rotated2 from "../../../assets/AboutUs/33.png";
import rotated3 from "../../../assets/AboutUs/34.png";
import write from "../../../assets/AboutUs/6.png";
import write2 from "../../../assets/AboutUs/35.png";
import detailimg from "../../../assets/AboutUs/20.png";
import "react-slideshow-image/dist/styles.css";
import Carousel from "react-elastic-carousel";
import { db } from "../../../firebaseTools";
import ReactCardCarousel from "react-card-carousel";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import { Popedprogram } from "../Programs/Program";
import FooterBody from "../FooterBody/Footerbody";
import {
  CheckButton,
  selectCheck,
  selectToggle,
  ToggleTheForm,
} from "../../../feature/ToggleSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Programs from "../Programs/Program";
import "react-toastify/dist/ReactToastify.css";
import mobileimage from "../../../assets/images/mobileImage.png";
import { Pop } from "../Navbar/navbar";
import appStore from "../../../assets/AboutUs/appstore.png";
import playStore from "../../../assets/AboutUs/playstore.png";

function AboutUs() {
  const [readmore, setreadmore] = useState(false);

  const [teamMember, setteamMember] = useState([]);
  const [mediaImage, setmediaImage] = useState([]);

  const toggle = useSelector(selectToggle);

  const checkbutton = useSelector(selectCheck);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const backColr = [
    { background: "linear-gradient(198.99deg, #FB8955 9.61%, #F9A93B 96.04%)" },
    { background: "linear-gradient(#B6A4DD ,#E0A1CC)" },
    {
      background: "linear-gradient(#4BB79E ,#50D659)",
    },
  ];
  let [toggle1, settoggle] = useState(0);

  function Popover() {
    if (toggle1 == 1) {
      dispatch(ToggleTheForm(true));
      document.getElementById("popover").style.display = "block";
    }
    if (toggle1 == 0) {
      dispatch(ToggleTheForm(false));
      document.getElementById("popover").style.display = "none";
    }
  }

  useEffect(() => {
    db.collection("teamMember").onSnapshot((snapshot) => {
      setteamMember(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });

    db.collection("mediadatabase").onSnapshot((snapshot) => {
      setmediaImage(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
    if (checkbutton === true) {
      dispatch(CheckButton(false));
    }
  }, []);

  const Validate = (data) => {
    const ywidth = window.screen.width;
    if (ywidth >= 1080) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    db.collection("subscribeEmail").doc().set({
      email: data.email,
    });
    notification();
  };

  const notification = () => {
    toast.success("You have successfully subscribed", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 400, itemsToShow: 2, itemsToScroll: 2 },
    { width: 600, itemsToShow: 3 },
    { width: 800, itemsToShow: 4 },

    { width: 1000, itemsToShow: 5 },
  ];

  const height = "700px";

  return (
    <div className="aboutus">
      <div>
        <Programs />
      </div>
      <div className="cloud_one">
        {!toggle ? (
          <>
            <img
              src={upcloud}
              style={{ zIndex: "1", width: "30%", height: "15%" }}
              className="upcloude1"
            />
          </>
        ) : (
          <img
            src={upcloud}
            style={{ zIndex: "1", width: "30%", height: "15%" }}
          />
        )}
      </div>
      <div className="homemobilelogo">
        <img
          src={mobilecloud}
          alt="top"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className="cloud_left">
        {!toggle ? (
          <img
            src={upcloud2}
            style={{ zIndex: "1", width: "100%", height: "100%" }}
          />
        ) : (
          <img
            src={upcloud2}
            style={{ zIndex: "1", width: "100%", height: "100%" }}
          />
        )}
      </div>
      <h1 style={{ zIndex: "10" }}>About Us</h1>
      <div className="write">
        {!toggle ? (
          <>
            <img
              src={write}
              style={{ zIndex: "2", width: "100%", height: "2000px" }}
              className="write1_img"
            />
            <img
              src={write2}
              className="write2_img"
              style={{ zIndex: "2", width: "100%", height: "60vh" }}
            />
          </>
        ) : (
          <img
            src={write}
            style={{ zIndex: "2", width: "100vw", height: "300px" }}
          />
        )}
      </div>
      {!toggle && (
        <div className="lastCloud">
          {/* <img
            src={lastCloud}
            style={{ zIndex: "3", width: "100%", height: "300vh" }}
          /> */}
        </div>
      )}

      <div
        className={`aboutus_Top ${readmore && `aboutus_toggle`}`}
        style={{ zIndex: "10" }}
      >
        <div className="aboutus_topFirst">
          <div className="card" style={{ backgroundColor: "whitesmoke" }}>
            <div className="card-body">
              <p className="card-text">
                According to multiple reports, 35% of Indian graduates are
                unemployed due to a lack of skills, 42% of those employed are
                dissatisfied with their jobs, and by 2030, 65% of Indian
                graduates won't have the basic skills for employability. There
                are nearly 35 crore students studying in India, 90%+ of them are
                aware of only the top 7 career options and opt for one of them
                under societal, parental, or peer pressure. That is where
                Infigon Futures comes into the picture.
                {readmore ? (
                  <span>
                    <span style={{ color: "blue", fontWeight: "700" }}>
                      Infigon Futures
                    </span>{" "}
                    is Indias leading career and education mentorship platform
                    where students right from Grade 6 to Post Graduation can
                    plan and prepare for their target Career. We make use of
                    Artificial Intelligence for students to analyze themselves
                    on 8 different parameters and help them choose a target
                    career from a pond of 51+ Industries and 1500+ Career
                    options. All of this is done under the supervision of expert
                    student mentors from IITs, IIMs, and other premier colleges
                    of India. We have also developed India's first automated
                    counseling mobile app called 'Infigon - The Career Mentor'
                    available on Play Store and App Store. Our target is to help
                    more than 1 million students prepare a career roadmap by
                    2025
                  </span>
                ) : (
                  "  ....."
                )}
                <button
                  className="read-more"
                  onClick={() =>
                    readmore ? setreadmore(false) : setreadmore(true)
                  }
                  style={{ zIndex: "10" }}
                >
                  {readmore ? "...read Less" : "readmore"}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      {!toggle && (
        <>
          <div className="aboutus_first">
            <div className="aboutus_firstbox">
              <img src={detailimg} />
            </div>
            <div className="aboutus_vision" style={{ zIndex: "10" }}>
              <div className="card card_size">
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ color: "#2F3778", textAlign: "center" }}
                  >
                    Vision
                  </h5>
                  <p className="card_tag">
                    To be the world’s most trusted career-planning company,
                    where individuals irrespective of their financial,
                    geographical or lingual bounds can find everything they need
                    to build for themselves a successful future.
                  </p>
                  <h5
                    className="card-title"
                    style={{ color: "#2F3778", textAlign: "center" }}
                  >
                    Mission
                  </h5>
                  <p className="card_tag">
                    To help 1 million students prepare a Career road map.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutus_second">
            <div className="second_ourteam">
              <div
                className="card"
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      color: "#2F3778",
                      textAlign: "center",
                      fontSize: "35px",
                      color: "#004aad",
                      textAlign: "left",
                      fontSize: "40px",
                      fontFamily: "Titillium Web Bold",
                      fontWeight: "700",
                    }}
                  >
                    Our Team
                  </h5>
                  <p
                    className="card_tag"
                    style={{
                      color: "#000000",
                      textAlign: "justify",
                      fontSize: "15px",
                      fontFamily: "Titillium Web Extra Light",
                    }}
                  >
                    To be the world’s most trusted career-planning company,
                    where individuals irrespective of their financial,
                    geographical or lingual bounds can find everything they need
                    to build for themselves a successful future.
                  </p>
                </div>
              </div>
            </div>
            <div className="ourteam_card">
              <Carousel breakPoints={breakPoints}>
                {teamMember.map((each, index) => (
                  <div
                    className="team_card"
                    style={{ background: backColr[index].background }}
                    key={index}
                  >
                    <img
                      src={each.data.image}
                      className="team_img"
                      alt="..."
                      style={{ height: "220px" }}
                    />
                    <div className="card-body card_body">
                      <p
                        className="card-text detail-text"
                        style={{ textAlign: "center" }}
                      >
                        {
                          <h5
                            className="card-title"
                            style={{ color: "#2F3778", textAlign: "center" }}
                          >
                            {each.data.name}
                          </h5>
                        }
                        "{each.data.description}"<br></br>{" "}
                      </p>
                      <p
                        className="card-text detail-text"
                        style={{
                          textAlign: "center",
                          fontFamily: "Titillium Web",
                          fontStyle: "italic",
                          fontSize: "14px",
                          lineHeight: "21px",
                          overflow: "hidden",
                        }}
                      >
                        {/* "{each.data.newfield}" */}
                      </p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="aboutus_third" style={{ zIndex: "10" }}>
            <div className="info_carousal">
              {/* <ReactCardCarousel
                className="rotated_card"
                autoplay={true}
                autoplay_speed={2500}
                style={{ zIndex: "10" }}
              >
                <img className="Card_float" src={rotated1} />
                <img className="Card_float" src={rotated2} />
                <img className="Card_float" src={rotated3} />
              </ReactCardCarousel> */}
              <img src={mobileimage} alt="..." className="aboutmobileimg" />
            </div>

            <div className="demo_info">
              <h5
                className="card-title"
                style={{
                  color: "#000000",
                  fontSize: "29px",
                  fontFamily: "Titillium Web Extra Light",
                  textAlign: "center",
                }}
              >
                Feel free to Contact us with your queries
              </h5>
              <br />
              <Link
                to="/"
                className="link_btn"
                style={{ textDecoration: "none" }}
              >
                <button
                  type="button"
                  className="btn  btn-lg book_demobtn"
                  style={{
                    color: "#ffffff",
                    fontSize: "18px",
                    fontFamily: "Titillium Web Bold",
                    textAlign: "center",
                  }}
                >
                  Book A Demo
                </button>
              </Link>
              <br />
              <div className="store_images">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tech.infigon"
                  target="_blank"
                  style={{ marginRight: "18px" }}
                >
                  <img
                    src={playStore}
                    style={{ width: "125px", height: "44px" }}
                    target="_blank"
                    alt="Download App from playstore"
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/infigon-the-career-mentor/id1564255604"
                  target="_blank"
                >
                  <img
                    src={appStore}
                    style={{ width: "125px", height: "44px" }}
                    alt="Download App from playstore"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="aboutus_fourth" style={{ zIndex: "10" }}>
            <h5>News</h5>
            <Carousel breakPoints={breakPoints} className="media_slider">
              {mediaImage.map((item, index) => (
                <a href={item.data.link} target="_blank" key={index}>
                  <img
                    className="media_image"
                    src={item.data.image}
                    alt="..."
                  />
                </a>
              ))}
            </Carousel>
          </div>
          <br />
          <div style={{ marginTop: "-2%" }}>
            <FooterBody />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
