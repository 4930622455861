import React, { useEffect, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import Carousel from "react-elastic-carousel";
import Pimage1 from "../../../assets/images/Process1.png";
import Pimage2 from "../../../assets/images/Process2.png";
import Pimage3 from "../../../assets/images/Process3.png";
import Pimage4 from "../../../assets/images/Process4.png";
import Pimage5 from "../../../assets/images/Process5.png";
import "./Process.css";
function Process() {
  //   const [ourProcess, setourProcess] = useState([]);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 600, itemsToShow: 2 },
    { width: 800, itemsToShow: 3 },
    { width: 900, itemsToShow: 4 },
    { width: 1000, itemsToShow: 5 },
  ];
  const Pcolors = ["#FF3636", "#C0215E", "#981D81", "#6D18A6", "#4314CA"];
  const Gradiants = [
    {
      background: "linear-gradient(90deg, #FF2828 0%, #D6234B 100%)",
    },
    {
      background: "linear-gradient(90deg, #D5234C 0%, #AC1F6F 100%)",
    },
    {
      background: "linear-gradient(90deg, #AB1F70 0%, #821B93 100%)",
    },
    {
      background: "linear-gradient(90deg, #811A94 0%, #5816B7 100%)",
    },
    {
      background: "linear-gradient(90deg, #5716B8 0%, #2E12DB 100%)",
    },
  ];

  const ProcessBody = [
    {
      Ptitle: "SELF-ANALYSIS",
      Pimage: Pimage1,
      Ptext:
        "Explore your interests, analyze your personality and evaluate your skills with a 20-minute psychometric test that saves time and provides efficient results.",
      marginTop: "12%",
      marginTop1:"2%",
      marginRight: "2%",
    },
    {
      Ptitle: "TARGET CAREER SELECTION",
      Pimage: Pimage2,
      Ptext:
        "Pick from an assortment of 1200+ career options custom curated to suit your needs and preferences.",
      marginTop: "-2%",
      marginTop1:"12%",
      marginRight: "2%",
    },
    {
      Ptitle: "EDUCATION PLANNING",
      Pimage: Pimage3,
      Ptext:
        "Plan your entire education road map right from junior college to your undergraduate and postgraduate studies.",
      marginTop: "4%",
      marginTop1:"4%",
      marginRight: "2%",
    },
    {
      Ptitle: "SKILL DEVELOPMENT",
      Pimage: Pimage4,
      Ptext:
        "Develop the necessary skills for your target career along the way to ensure you stand apart from all the competition and make your choice a financial success. ",
      marginTop: "4%",
      marginTop1:"0%",
      marginRight: "2%",
    },
    {
      Ptitle: "EXPERT MENTORSHIP",
      Pimage: Pimage5,
      Ptext:
        "A year-long subscription to experts with a 24*7 access to consult them anytime anywhere you have a query via live interactions, calls or chats.",
      marginTop: "5%",
      marginTop1:"0%",
      marginRight: "2%",
    },
  ];
  return (
    <div>
      <div id="Fiveprocess">
        <div id="Fiveprocesstitle">Our 5 Step Process</div>
        <br />
        <div id="processbody">
          <Carousel breakPoints={breakPoints}>
            {ProcessBody.map((each, index) => (
              <div className="Process_card">
                <div
                  className="Processtitle"
                  key={index}
                  style={{ color: Pcolors[index] }}
                >
                  {each.Ptitle}
                </div>
                <div
                  className="Processtext"
                  key={index}
                  style={{ color: Pcolors[index], marginTop: each.marginTop}}
                >
                  {each.Ptext}
                </div>
                <div className="Processimage" key={index}>
                  <img
                    src={each.Pimage}
                    alt="imags"
                    style={{ textAlign: "center",marginTop: each.marginTop1,width:"100%",height:"100%"}}
                    
                  ></img>
                </div>
                <br />
                <br />
                <div
                  className="Processborder"
                  key={index}
                  style={{
                    background: Gradiants[index].background,
                    width: "100%",
                    height: "15px",
                    marginRight: each.marginLeft,
                    textAlign: "center",
                  }}
                ></div>
              </div>
            ))}
          </Carousel>
          <div id="triangle"></div>
        </div>
        <div id="processbottom">
          <div className="process_1">CONFUSION</div>
          <div className="process_2" style={{ float: "right" }}>
            CLARITY
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
