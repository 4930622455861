import { createSlice } from '@reduxjs/toolkit'

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    amount: "",
    check:false,
    program:"",
  },
  reducers: {
    AmountForm: (state, action) => {
      state.amount = action.payload; 
    },
    PROGRAMTYPE: (state, action) => {
      state.program = action.payload; 
    },
  },
})

// Action creators are generated for each case reducer function
export const { AmountForm,PROGRAMTYPE} = paymentSlice.actions

export const selectAmount = (state) => state.payment.amount;
export const selectProgram = (state) => state.payment.program;
// export const selectClass = (state) => state.toggle.class;

export default paymentSlice.reducer