import React from 'react'
import styles from '../TestResult.module.css'

function Test3({result}) {
  return (
    <>
                {
                    result &&
                    <>
                    {
                        result.Stream &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Stream</div>
                            <div className={styles.content}>{result.Stream}</div>
                        </div>
                    }

                    {
                        result["Career Clusters"] &&
                        <div className={styles.section}>
                            <div className={styles.Title}>Career Clusters</div>
                            <div>
                                {
                                    result["Career Clusters"].map(item => {
                                        return (
                                            <div className={styles.content}>{item}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }

                    </>
                }
                </>
  )
}

export default Test3