import React from "react";
import "./Testimonialsbody.css";
import Timg1 from "../../../assets/images/tw1.png";
import Timg2 from "../../../assets/images/tw2.png";
import Timg3 from "../../../assets/images/tw3.png";
import Timg4 from "../../../assets/images/tw4.png";
import Timg5 from "../../../assets/images/tw5.png";
import bottomimg1 from "../../../assets/images/Vector 7.png";
import bottomimg2 from "../../../assets/images/Vector 8.png";
import FooterBody from "../FooterBody/Footerbody";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Timages = [{ Twtimages: Timg1 }, { Twtimages: Timg2 }];
const Timages1 = [{ Twtimages1: Timg3 }, { Twtimages1: Timg4 }];
const Timages2 = [{ Twtimages2: Timg5 }];

function Testimonialsbody() {
  const Testimages = [
    { image: Timg1, image2: Timg2 },
    { image: Timg3, image2: Timg4 },
    { image: Timg5, image2: Timg1 },
  ];

  return (
    <div>
      <br />
      <div>
        <div id="testimonials1">
          <div id="testimonialstitle">Testimonials</div>
          <br />
          <br />
          {/* <br /> */}
          <br />

          <Carousel
            infiniteLoop={true}
            interval={2000}
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            showIndicators={false}
            autoPlay={true}
          >
            {Testimages.map((each, index) => (
              <div
                style={{marginRight:"25px", 
                  marginTop:"20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  src={each.image}
                  alt="..."
                  style={{ width: "45%", height: "150px", marginRight: "30px", marginLeft:"25px", }}
                  className="slider_images"
                />
                <div style={{ marginRight: "2%" }}></div>
                <img
                  src={each.image2}
                  alt="..."
                  style={{ width: "45%", height: "140px", marginRight: "5%" }}
                  className="slider_images"
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div id="imagesoftest">
          <div id="bottomimage1">
            <img
              src={bottomimg1}
              alt="..."
              style={{ width: "20%", height: "100%" }}
            ></img>
          </div>
          <div id="bottomimage2">
            <img src={bottomimg2} alt="..." style={{ float: "right" }}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonialsbody;
