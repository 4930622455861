import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import "./Payment.css";
import Programs from "../Programs/Program";
import { db } from "../../../firebaseTools";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import firebase from "firebase/compat/app";
import upcloud from "../../../assets/AboutUs/37.png";
import logopng from "../../../assets/images/logo.png";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import { useHistory } from "react-router";
import {
  AmountForm,
  selectAmount,
  selectProgram,
} from "../../../feature/PaymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { CheckButton, selectCheck } from "../../../feature/ToggleSlice";
let formData = new FormData();

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function loadS(src) {
  return new Promise((resolve) => {
    src.onload = () => {
      resolve(true);
    };
    src.onerror = () => {
      resolve(false);
    };
  });
}

const coupon = "INFU50";

function Payment() {
  useEffect(() => {
    db.collection("coupon").onSnapshot((snapshot) => {
      setcoupons(
        snapshot.docs.map((doc) => ({
          data: doc.data(),
        }))
      );
    });
  }, []);
  const dispatch = useDispatch();

  var paymentamount = useSelector(selectAmount);
  const checkbutton = useSelector(selectCheck);
  const programtype = useSelector(selectProgram);

  const [paymentInfo, setPaymentInfo] = useState({
    Name: "",
    Email: "",
    Number: "",
    Address: "",
    Pincode: "",
    Grade: "6",
    School: "",
    coupon: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [coupons, setcoupons] = useState([]);

  const [couponpass, setcouponpass] = useState(false);

  const history = useHistory();

  function discountPrize(a, b) {
    return (a * b) / 100;
  }

  async function displayRazorpay(e) {
    e.preventDefault();
    console.log(paymentInfo);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const data = await axios.post("https://apihostray.herokuapp.com/razorpay", {
      amount: paymentamount,
    });
    console.log(data);
    console.log(data.data.amount);

    const options = {
      key: "rzp_live_3ekXb6waRBpIee",
      amount: `${data.data.amount}`,
      order_id: data.data.id,
      name: "Infigon Future",
      description: "Thank you for Trust.",
      image: logopng,
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);

        formData.append("name", paymentInfo.Name); //append the values with key, value pair
        formData.append("email", paymentInfo.Email);
        formData.append("price", data.data.amount / 100);
        formData.append("test", programtype);

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        axios
          .post(
            "https://paymentemail.infigonfutures.com/params",
            formData,
            config
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });

        db.collection("payment_info")
          .doc(data.data.order_id)
          .set({
            name: paymentInfo.Name,
            email: paymentInfo.Email,
            mobile: paymentInfo.Number,
            address: paymentInfo.Address,
            amount: data.data.amount / 100, //Don't remove the 100
            Pincode: paymentInfo.Pincode,
            Grade: paymentInfo.Grade,
            School: paymentInfo.School,
            coupon: paymentInfo.coupon,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
        toast.success("You are successfully subscribe", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/");
      },
      prefill: {
        name: paymentInfo.Name,
        email: paymentInfo.Email,
        phone_number: paymentInfo.Number,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const couponChecker = () => {
    console.log(coupons);

    const index = coupons.findIndex(
      (coupons) => coupons.data.coupon === paymentInfo.coupon
    );
    console.log(index);
    console.log(couponpass);

    if (index >= 0 && checkbutton === false && checkbutton === false) {
      var offer = paymentInfo.coupon.substr(paymentInfo.coupon.length - 2);
      var finalPrize = discountPrize(paymentamount, offer);
      dispatch(AmountForm(`${paymentamount-finalPrize}`));
      dispatch(CheckButton(true));
    } else if (checkbutton === true) {
      toast.warning("You have already applied the coupon", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning("Enter coupon is wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <div className="payment">
        <div className="upcloud_pay">
          <img
            src={upcloud}
            style={{ zIndex: "1", width: "100%", height: "100%" }}
            className="upcloude1"
            alt="....."
          />
        </div>
        <form
          className="detail_form"
          style={{ width: "100%", zIndex: "12" }}
          onSubmit={(e) => displayRazorpay(e)}
        >
          <div className="popbody" style={{ zIndex: "12" }}>
            <br />
            <h3>
              <span className="future_pay">
                <span style={{ color: "#728FCE" }}>IN</span>
                <span style={{ color: "#E38AAE" }}>FI</span>
                <span style={{ color: "#FFA07A" }}>GON</span>
              </span>{" "}
              <span className="future_pay">
                <span style={{ color: "#728FCE" }}>FU</span>
                <span style={{ color: "#E38AAE" }}>TU</span>
                <span style={{ color: "#FFA07A" }}>RE</span>
              </span>{" "}
            </h3>
            <div className="mb-1" style={{ zIndex: "12" }}>
              <label
                htmlFor="exampleInputEmail1"
                className="form-label form_label"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control form_control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => {
                  setPaymentInfo({ ...paymentInfo, Name: e.target.value });
                }}
                required
              />
            </div>
            <div className="mb-1" style={{ zIndex: "12" }}>
              <label
                htmlFor="exampleInputEmail1"
                className="form-label form_label"
              >
                Contact Number
              </label>
              <input
                type="tel"
                className="form-control form_control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                required
                onChange={(e) => {
                  setPaymentInfo({ ...paymentInfo, Number: e.target.value });
                }}
                maxLength="10"
              />
            </div>
            <div className="mb-1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label form_label"
              >
                Email ID
              </label>
              <input
                type="email"
                className="form-control form_control"
                name="email"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => {
                  setPaymentInfo({ ...paymentInfo, Email: e.target.value });
                }}
                required
              />
            </div>
            <div className="mb-1">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label form_label"
              >
                Choose Your Grade
              </label>
              <select
                className="form-select h-100 w-50 gradInputfield"
                aria-label=".form-select-lg example"
                style={{ marginLeft: "25%" }}
                onChange={(e) => {
                  setPaymentInfo({ ...paymentInfo, Grade: e.target.value });
                }}
              >
                <option value="6" selected>
                  6
                </option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
              </select>
            </div>

            <div className="mb-1">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label form_label"
              >
                Location (City - State)
              </label>
              <input
                type="text"
                className="form-control form_control"
                id="exampleInputPassword1"
                onChange={(e) => {
                  setPaymentInfo({ ...paymentInfo, Address: e.target.value });
                }}
                required
              />
            </div>
            <div className="mb-1">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label form_label"
              >
                School
              </label>
              <input
                type="text"
                className="form-control form_control"
                id="exampleInputPassword1"
                onChange={(e) => {
                  setPaymentInfo({ ...paymentInfo, School: e.target.value });
                }}
                required
              />
            </div>
            <div className="mb-1">
              <label
                htmlFor="exampleInputPassword1"
                className="form-label form_label"
              >
                Pin Code
              </label>
              <input
                type="text"
                className="form-control form_control"
                id="exampleInputPassword1"
                onChange={(e) => {
                  setPaymentInfo({ ...paymentInfo, Pincode: e.target.value });
                }}
                required
              />
            </div>
            <div className="mb-1 ">
              <label
                htmlFor="exampleInputcoupon1"
                className="form-label form_label"
                id="couponblock"
              >
                Have a Coupon Code?
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  className="col-4 form-control form_control exampleInputcoupon"
                  id="exampleInputcoupon"
                  style={{ marginLeft: "25%" }}
                  onChange={(e) => {
                    setPaymentInfo({ ...paymentInfo, coupon: e.target.value });
                  }}
                />
                <Button
                  id="applycode"
                  style={{ marginRight: "25%" }}
                  onClick={() => {
                    couponChecker();
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
            <p
              id="invalidcoupon"
              style={{ color: "red", textAlign: "center" }}
            ></p>

            <br />
            <Button
              type="submit"
              style={{
                marginLeft: "35%",
                marginBottom: "2%",
                background: "rgb(82, 170, 211)",
                color: "white",
              }}
              className="btn btn-primary"
              id="Paybutton"
            >
              PAY {paymentamount}
            </Button>
          </div>
          <ToastContainer />
        </form>
      </div>
      <div>
        <Programs />
      </div>
    </div>
  );
}

export default Payment;

// const paymentDetails = (e) => {
//   if (paymentamount === "5000") {
//     window.open("https://rzp.io/i/kxngDSNl", "_blank");
//   } else if (paymentamount === "2500") {
//     window.open("https://rzp.io/i/qyUsaEe", "_blank");
//   } else if (paymentamount === "10000") {
//     window.open("https://rzp.io/i/ZpbLMPkR", "_blank");
//   } else if (paymentamount === "15000") {
//     window.open("https://rzp.io/i/6zXumTqmLv", "_blank");
//   } else if (paymentamount === "7500") {
//     window.open("https://rzp.io/i/3VuXtkQm", "_blank");
//   }
//   db.collection("payment_info").doc().set({
//     name: paymentInfo.Name,
//     email: paymentInfo.Email,
//     mobile: paymentInfo.Number,
//     address: paymentInfo.Address,
//     amount: paymentamount, //Don't remove the 100
//     Pincode: paymentInfo.Pincode,
//     Grade: paymentInfo.Grade,
//     School: paymentInfo.School,
//     coupon: paymentInfo.coupon,
//     // order_id: response.razorpay_order_id,
//     // payment_id: response.razorpay_payment_id,
//     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
//   });
//   dispatch(CheckButton(false));
//   toast.success("You are successfully subscribe", {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   });
//   history.push("/");
// };
/* <Helmet> <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
<script>
var options = {
    "key": "YOUR_KEY_ID", // Enter the Key ID generated from the Dashboard
    "amount": "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Acme Corp",
    "description": "Test Transaction",
    "image": "https://example.com/your_logo",
    "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": function (response){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature)
    },
    "prefill": {
        "name": "Gaurav Kumar",
        "email": "gaurav.kumar@example.com",
        "contact": "9999999999"
    },
    "notes": {
        "address": "Razorpay Corporate Office"
    },
    "theme": {
        "color": "#3399cc"
    }
};
var rzp1 = new Razorpay(options);
rzp1.on('payment.failed', function (response){
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
});
document.getElementById('rzp-button1').onclick = function(e){
    rzp1.open();
    e.preventDefault();
}
</script>

          </Helmet> */
