import firebase from 'firebase/compat/app';
import "firebase/compat/auth"
import "firebase/compat/firestore"
// import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAVUPeXzENcDlp_vd3DNBhnaBEjqXuOXew",
  authDomain: "infigon-3597a.firebaseapp.com",
  databaseURL: "https://infigon-3597a.firebaseio.com",
  projectId: "infigon-3597a",
  storageBucket: "infigon-3597a.appspot.com",
  messagingSenderId: "903090227907",
  appId: "1:903090227907:web:a0d44d9b1a7b14b1897076",
  measurementId: "G-JG67CRP6B1"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

// const firebaseApp=firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

const auth = firebase.auth();
// const storage=firebase.storage();


export { db, auth};
